import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index';
import MSG from '../contants/msg';
// 레이아웃 페이지
// import Login from '../pages/Login.vue' // 로그인 화면
// import Signup from '../pages/MSignup.vue' // 로그인 화면

import Layout from '../Layout.vue' // 로그인후 레이아웃 화면
import NoFooterLayout from '../NoFooterLayout.vue' // 로그인후 레이아웃 화면

// 컨텐츠 페이지
import Index from '../pages/Index' // 대쉬보드
import Deposit from '../pages/Deposit' // 입금
import Withdrawal from '../pages/Withdrawal' // 출금
import Recommender from '../pages/Recommender' // 추천인
// import PointList from '../pages/PointList' // 포인트내역
import PointChange from '../pages/PointChange' // 포인트전환

import Mypage from '../pages/Member/Mypage' // 마이페이지
import MessageList from "../pages/Message/List";
import MessageDetail from "../pages/Message/Detail";


// import GameResult from '../pages/GameResult/GameResult' // 경기결과
// import NotFound from '../pages/404' // 출금
// import Fixed from '../pages/500' // 출금
// import Level from '../pages/Level' // 레벨별 혜택

import BettingResult from '../pages/BettingResult' // 포인트내역
// //
import { InplayList, EuropeanList, KoreanList } from './sports' // 스포츠 베팅
// import { EventRoulette, EventChulsuk } from './events' // 스포츠 베팅
// import { Main as MiniMain, NPowerBall, NPowerLadder, DHPowerBall } from "./minigame"; // 스포츠 베팅
// //
import {
  EventList,
  EventDetail,
  // FaqList,
  NoticeList,
  NoticeDetail,
  //
  RuleList,
  RuleDetail,
  CustomerList ,
  CustomerDetail,
  // CustomerBettingAdd,
  CustomerWrite,
} from './board'
import { Roulette,  Baccarat, HighLow, HighLow5s, Graph, Keno, TokenMain } from './token' // 토큰게임
import { LiveMain, SlotMain } from './casino' // 카지노
import { SlotLaunching } from './slot' // 슬롯
import { Oddeven, Baccarat1, LotusMain } from './lotus' // 로투스

//미니게임
// import { PowerBall, PowerLadder } from './minigame'


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    // console.log('RELOAD location >> ', location)
    return window.location.reload();
  });
};
Vue.use(VueRouter)


// 라우팅 정의
const routes = [

  {
    path: "/",
    component: Layout,
    props: { currentMenu: "메인" },
    children: [
      {
        path: "/",
        name: "메인",
        component: Index,
        meta: { requireAuth: false },
      },
    ],
  }, // 메인화면
  // {
  //   path: "/level",
  //   component: Layout,
  //   props: { currentMenu: "레벨별 혜택" },
  //   children: [
  //     {
  //       path: "/",
  //       name: "레벨별 혜택",
  //       component: Level,
  //       meta: { requireAuth: false },
  //     },
  //   ],
  // }, // 메인화면

  {
    path: "/point",
    name: "POINT",
    component: Layout,
    children: [
      {
        path: "",
        name: "포인트 전환",
        component: PointChange,
        meta: { requireAuth: true },
      },
      // {
      //   path: "list",
      //   name: "포인트내역",
      //   component: PointList,
      //   meta: { requireAuth: true },
      // },
    ],
  },

  {
    path: "/balance",
    name: "BALANCE",
    component: Layout,
    children: [
      {
        path: "deposit",
        name: "입금",
        component: Deposit,
        meta: { requireAuth: true },
      },
      {
        path: "withdrawal",
        name: "출금",
        component: Withdrawal,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/sport",
    name: "SPORT",
    component: NoFooterLayout,
    children: [
      {
        path: "inplay",
        name: "스포츠-인플레이(벳컨)",
        component: InplayList,
        meta: { requireAuth: true },
      },
      {
        path: "european",
        name: "스포츠-유럽형(벳컨)",
        component: EuropeanList,
        meta: { requireAuth: true },
      },
      {
        path: "korean",
        name: "스포츠-한국형(벳컨)",
        component: KoreanList,
        meta: { requireAuth: true },
      },
      // {path:"live/d", name: "스포츠-실시간|자세히", component:InplayDetail, meta: { requireAuth: false }},
      // {
      //   path: "live/d",
      //   name: "스포츠-인플레이(자세히)",
      //   component: InplayDetail2,
      //   meta: { requireAuth: false },
      // },
      // {
      //   path: "result",
      //   name: "스포츠-경기결과",
      //   component: GameResult,
      //   meta: { requireAuth: false },
      // },
      // {
      //   path: "lsport/prematch",
      //   name: "스포츠-엘스포츠(유럽형)",
      //   component: LsportEuropean,
      //   meta: { requireAuth: false },
      // },
    ],
  },
  // {
  //   path: "/event",
  //   name: "EVENT",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "roulette",
  //       name: "이벤트-룰렛",
  //       component: EventRoulette,
  //       meta: { requireAuth: true },
  //     },
  //     // {
  //     //   path: "lotto",
  //     //   name: "이벤트-로또",
  //     //   component: EventLotto,
  //     //   meta: { requireAuth: true },
  //     // },
  //     {
  //       path: "chulsuk",
  //       name: "이벤트-출석",
  //       component: EventChulsuk,
  //       meta: { requireAuth: true },
  //     },
  //   ],
  // },
  {
    path: "/lotus",
    name: "LOTUS",
    component: Layout,
    children: [
      {
        path: "main",
        name: "로투스-메인",
        component: LotusMain,
        meta: { requireAuth: true },
      },
      {
        path: "oe",
        name: "로투스-홀짝",
        component: Oddeven,
        meta: { requireAuth: true },
      },
      {
        path: "baccarat1",
        name: "로투스-바카라1",
        component: Baccarat1,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/casino",
    name: "CASINO",
    component: Layout,
    children: [
      {
        path: "live",
        name: "카지노-라이브",
        component: LiveMain,
        meta: { requireAuth: true },
      },
      {
        path: "slot",
        name: "카지노-슬롯",
        component: SlotMain,
        meta: { requireAuth: true },
      },
      // {
      //   path: "mlive",
      //   name: "카지노-모바일라이브",
      //   component: MobileLiveMain,
      //   meta: { requireAuth: true },
      // },
      // {
      //   path: "mslot",
      //   name: "카지노-모바일슬롯",
      //   component: MobileSlotMain,
      //   meta: { requireAuth: true },
      // },
    ],
  },
  {
    path: "/slots",
    name: "SLOT",
    component: Layout,
    children: [
      {
        // path: ":product_id/:type",
        path: ":product_id/:type",
        name: "카지노-슬롯",
        component: SlotLaunching,
        meta: { requireAuth: true },
      },

    ],
  },
  {
    path: "/token",
    name: "TOKEN",
    component: Layout,
    children: [
      {
        path: "main",
        name: "토큰-메인",
        component: TokenMain,
        meta: { requireAuth: false },
      },
      {
        path: "roulette",
        name: "토큰-룰렛",
        component: Roulette,
        meta: { requireAuth: true },
      },
      {
        path: "keno",
        name: "토큰-키노",
        component: Keno,
        meta: { requireAuth: true, isRepair: true },
      },
      {
        path: "graph",
        name: "토큰-그래프",
        component: Graph,
        meta: { requireAuth: true, isRepair: true },
      },
      {
        path: "highlow",
        name: "토큰-하이로우",
        component: HighLow,
        meta: { requireAuth: true },
      },
      {
        path: "highlow5s",
        name: "토큰-하이로우5초",
        component: HighLow5s,
        meta: { requireAuth: true },
      },
      {
        path: "baccarat",
        name: "토큰-바카라",
        component: Baccarat,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/my",
    name: "MyPage",
    component: Layout,
    props: { currentMenu: "마이페이지" },
    children: [
      {
        path: "page",
        name: "마이페이지",
        component: Mypage,
        meta: { requireAuth: true },
      },
      {
        path: "betting-result",
        name: "베팅내역",
        component: BettingResult,
        meta: { requireAuth: true },
      },
      {
        path: "invite",
        name: "추천인",
        component: Recommender,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/board",
    component: Layout,
    children: [
      {
        path: "support/list",
        name: "고객센터-목록",
        component: CustomerList,
        meta: { requireAuth: true },
      },
      {
        path: "support/detail/:seq",
        name: "고객센터-자세히",
        component: CustomerDetail,
        meta: { requireAuth: true },
      },
      {
        path: "support/register",
        name: "고객센터-글쓰기",
        component: CustomerWrite,
        meta: { requireAuth: true },
      },
      {
        path: "notice/list",
        name: "공지사항-목록",
        component: NoticeList,
        meta: { requireAuth: false },
      },
      {
        path: "notice/detail/:seq",
        name: "공지사항-자세히",
        component: NoticeDetail,
        meta: { requireAuth: false },
      },
      {
        path: "event/list",
        name: "이벤트-목록",
        component: EventList,
        meta: { requireAuth: false },
      },
      {
        path: "event/detail/:seq",
        name: "이벤트-자세히",
        component: EventDetail,
        meta: { requireAuth: true },
      },
      {
        path: "betting-rule",
        name: "규정-목록",
        component: RuleList,
        meta: { requireAuth: false },
      },
      {
        path: "betting-rule/:seq",
        name: "규정-자세히",
        component: RuleDetail,
        meta: { requireAuth: false },
      },
    ],
  },
  {
    path: "/message",
    component: Layout,
    children: [
      {
        path: "list",
        name: "쪽지-목록",
        component: MessageList,
        meta: { requireAuth: true },
      },
      {
        path: "detail/:seq",
        name: "쪽지-자세히",
        component: MessageDetail,
        meta: { requireAuth: true },
      },
    ]
  },
  // {
  //   path: "/mini",
  //   name: "MINIGAME",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "home",
  //       name: "미니게임-메인",
  //       component: MiniMain,
  //       meta: { requireAuth: false },
  //     },
  //     {
  //       path: "npowerball",
  //       name: "미니게임-N파워볼",
  //       component: NPowerBall,
  //       meta: { requireAuth: true },
  //     },
  //     {
  //       path: "npowerladder",
  //       name: "미니게임-N파워사다리",
  //       component: NPowerLadder,
  //       meta: { requireAuth: true },
  //     },
  //     {
  //       path: "dhpowerball",
  //       name: "미니게임-동행파워볼",
  //       component: DHPowerBall,
  //       meta: { requireAuth: true },
  //     },
  //   ],
  // },

  // {
  //   path: "/404",
  //   name: "notFound",
  //   component: NotFound,
  // },
  // {
  //   path: "/500",
  //   name: "fixed",
  //   component: Fixed,
  // },
  { path: "/", redirect: "/" },
  { path: "*", redirect: "/" },
];



const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});


router.beforeEach((to, from, next) => {
  //authenticationState는 유저가 로그인이 되어있는지 아닌지 값을 가져와 판별해준다.
  const isLoggined = store.state.user?.user;
  store.state.modals.mmenu = false;
  //authorization에서는 라우터에서 메타 속성을 정의해준 값이 담겨진다.
  // undefined, [], ["admin"], ["client"]가 올 수 있다.
  if (to.meta.requireAuth && !isLoggined){
    return alert( MSG.isNotLogin);
    // return alert(MSG.isNotLogin)
  }
  if (to.meta.isRepair){
    return alert('점검중 입니다.');
    // return alert(MSG.isNotLogin)
  }
  next();
});

export default router
