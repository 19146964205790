<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="Logo"></span><span>공지</span></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice">
                    <div class="noticeFrame">
                      <div>
                        <div class="listZone">
                          <table>
                            <tbody>
                            <tr>
                              <th class="ac number" width="10%"> No.</th>
                              <th class="ac" width="70%">제목</th>
                              <th class="ac name" width="20%">작성자</th>
                            </tr>
                            <tr v-for="(row, index) in lists" :key="'notice'+index" @click="movePage(row.board_seq)">
                              <td class="ac">
                                {{ index + 1}}
<!--                                <div class="important">중요</div>-->
                              </td>
                              <td class="al">
                                <a href="javascript: void(0)">{{ row.board_title }}</a>
                              </td>
                              <td class="ac fc">
                                관리자
                              </td>
                            </tr>
                            </tbody>
                          </table>
<!--                          <div style="" class="">-->
<!--                            <div class="noDataFrame">-->
<!--                              <div class="pic"><img src="@/assets/imgaes/noData.png" alt="no-data"></div>-->
<!--                              <h2>데이터 없음</h2>-->
<!--                              <h3>Sorry, Check no data</h3></div>-->
<!--                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section v-else id="content" class="">
    <div class="wrapPage">
      <ul class="tab-list">
        <li class="active">
          <router-link :to="{ name: '공지사항-목록'}">공지</router-link>
        </li>
        <li>
          <router-link :to="{ name: '이벤트-목록'}">이벤트</router-link>
        </li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>공지</div>
          <span>Notification</span></div>
        <div class="content-table">
          <table>
            <thead>
            <tr>
              <th class="w20"> No.</th>
              <th class="w60">제목</th>
              <th class="w20">작성자</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in lists" :key="'notice'+index" @click="movePage(row.board_seq)">
              <td class="w20">
                <span>{{ index + 1 }}</span>
              </td>
              <td class="w60 left">{{ row.board_title }}</td>
              <td class="w20">
                <span>관리자</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="absolute! top-0"></div>
        <!--        <div class="pagination">-->
        <!--          <ul>-->
        <!--            <li class="first"><a href="javascript: void(0)">처음</a></li>-->
        <!--            <li class="prev"><a href="javascript: void(0)">이전</a></li>-->
        <!--            <li class="pages on"><a href="javascript: void(0)">1</a></li>-->
        <!--            <li class="next"><a href="javascript: void(0)">다음</a></li>-->
        <!--            <li class="last"><a href="javascript: void(0)">끝</a></li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
// import Pagination from '../../../components/Pagination01'
// import SideLink from '../../../components/Board/SideLink'
// import Loading from '../../../components/Loading'

export default {
  name: "NoticeList",
  props: ['isMobile'],
  components: {
    // Pagination,
    // SideLink,
    // Loading
  },
  data: function () {
    return {
      path: 'bbs/n/l',
      lists: null,
      isLoading: false,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
        type: ['notice'],
        sitename: 'newbet'
      },
      expandedArticle: []
    }
  },
  mounted: function () {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function (payload) {
      this.isLoading = false;
      return this.$store.dispatch('GET_BOARD', {payload})
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.isLoading = true;
            console.log('페이로드', Array.isArray(this.payload.type))
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    changeType(type) {
      this.payload.type = type
      this.reqData(this.payload);
    },
    movePage: function (page) {
      console.log(page);
      this.$router.push({name: `공지사항-자세히`, params: {seq: page}})
    },
    renderHtml: function (str) {
      str.replace(/<img/g, '<img ')
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggleArticle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0) {
        this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1);
      } else this.expandedArticle.push(index);
    },
  }
}
</script>

<style scoped>
.content-table .table-btn.important {
  background: var(--btnColor06)
}

</style>
