<template>
  <div v-if="popup_bg">
    <div class="vfm vfm--inset vfm--fixed" style="z-index: 1006;">
      <div class="vfm__overlay vfm--overlay vfm--absolute vfm--inset"></div>
        <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none"
            v-for="(popup, index) in event_popup" v-if="popup.isVisible" :key="'modalPopup'+index"
            role="dialog" aria-modal="true" tabindex="-1" style="">
            <div class="vfm__content">
                <div class="model-main popup-ad model-open">
                    <div class="model-inner">
                        <div class="model-wrap">
                            <div class="pop-up-content">
                                <div class="ad-block">
                                    <div class="item">
                                        <div class="h-top">
                                            <div class="close-btn" @click="closePopup(popup)">
                                                <span class="icon-close"></span>
                                            </div>
                                            <img  src="../assets/img/main/main_toplogo.png" alt="logo">
                                        </div>
                                        <div v-html="popup.url"></div>

                                        <div class="not-show">
                                            <button class="btn-submit-common" @click="closePopup(popup)">오늘 하루 열지 않기</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
// import { pop } from 'core-js/core/array';

export default {
  name: "MEventPopUp",
  computed: {
    event_popup: function(){
      return this.$store.state.event.event_popup;
    },
    popup_bg() {
       console.log('Checking popup_bg visibility');
       return this.event_popup.find((pop) => pop.isVisible);
    },
  },
  methods: {
    closePopup(popup){
      popup.isVisible = false;
      this.$store.dispatch('CLOSE_MODAL', { seq: popup.seq });
    },
    setInVisible(popup){
      const date = new Date();
      date.setDate(date.getDate() + 1);
      popup.isSetDate = date;
      popup.isVisible = false;
      this.closePopup(popup);
      localStorage.setItem('popups',JSON.stringify(this.$store.state.event.event_popup));
      this.$forceUpdate();
    },
    isVisible(popup) {
      const storagePopup = localStorage.getItem('popups');
      const popups = JSON.parse(storagePopup) || [];
      const p = popups.find(e => e.seq === popup.seq);
      if (!p) return true;
      if (!p.isSetDate && p.isVisible) return true;
      if (p.isSetDate && new Date(p.isSetDate) < new Date()) return true;
      return false;
    },
    savePopupState() {
      localStorage.setItem('popups', JSON.stringify(this.$store.state.event.event_popup));
    }
  }
}
</script>
<style scoped>
.vfm--inset {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.vfm--fixed{
    position: fixed;
}
.vfm--overlay {
    background-color: rgba(0, 0, 0, 0.5);
}
.vfm--absolute {
    position: absolute;
}
.popup-ad.model-open,.popup-ad .model-wrap {
    overflow: hidden
}

.popup-ad .ad-block {
    position: relative;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.popup-ad .ad-block .item {
    position: relative;
    width: 85.333vw;
    min-height: 46.667vw;
    max-height: 60vh;
    border-radius: 4vw;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto
}

.popup-ad .ad-block .item .main-img {
    overflow-y: scroll
}

.popup-ad .ad-block .item p img {
    height: auto!important
}

.popup-ad .ad-block .close-btn {
    background: var(--bgColor24);
    padding: 1vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 8vw;
    height: 8vw;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.popup-ad .ad-block .close-btn span.icon-close {
    color: var(--txtColor01);
    font-size: 1rem
}

.popup-ad .ad-block .not-show {
    background: var(--bgColor01);
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 3vw 2vw;
    border-radius: 0 0 4vw 4vw;
    -webkit-border-radius: 0 0 4vw 4vw;
    -moz-border-radius: 0 0 4vw 4vw;
    -ms-border-radius: 0 0 4vw 4vw;
    -o-border-radius: 0 0 4vw 4vw
}

.popup-ad .ad-block .not-show button {
    padding: 2vw 3vw;
    border-radius: 10vw;
    background: var(--bgColor02);
    color: var(--txtColor01);
    font-size: .93rem;
    -webkit-border-radius: 10vw;
    -moz-border-radius: 10vw;
    -ms-border-radius: 10vw;
    -o-border-radius: 10vw
}

.popup-ad .ad-block .h-top {
    position: relative;
    background: var(--bgColor01);
    border-radius: 4vw 4vw 0 0;
    -webkit-border-radius: 4vw 4vw 0 0;
    -moz-border-radius: 4vw 4vw 0 0;
    -ms-border-radius: 4vw 4vw 0 0;
    -o-border-radius: 4vw 4vw 0 0
}

.popup-ad .ad-block .h-top img {
    margin: 2vw auto;
    max-width: 24%
}

.popup-ad.model-main {
    opacity: 1
}

.popup-ad .model-inner .ad-block .item {
    transition: unset;
    -webkit-transition: unset;
    -moz-transition: unset;
    -ms-transition: unset;
    -o-transition: unset;
    -webkit-animation: modalClose .5s ease-in-out;
    -moz-animation: modalClose .5s ease-in-out;
    -o-animation: modalClose .5s ease-in-out;
    animation: modalClose .5s ease-in-out
}

.popup-ad.model-open .model-inner .ad-block .item {
    -webkit-animation: modalOpen .5s ease-in-out;
    -moz-animation: modalOpen .5s ease-in-out;
    -o-animation: modalOpen .5s ease-in-out;
    animation: modalOpen .5s ease-in-out
}

.popup-ad .bg-overlay {
    -webkit-transition: .1s ease-in-out;
    -o-transition: .1s ease-in-out;
    -moz-transition: .1s ease-in-out;
    transition: .1s ease-in-out;
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s
}

@-webkit-keyframes modalOpen {
    0% {
        -webkit-transform: translateY(2000px);
        -moz-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        -o-transform: translateY(2000px);
        transform: translateY(2000px)
    }

    to {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0)
    }
}

.modal-close {
    -webkit-animation: modalClose 2s ease-in-out;
    -moz-animation: modalClose 2s ease-in-out;
    -o-animation: modalClose 2s ease-in-out;
    animation: modalClose 2s ease-in-out
}
</style>
