<template>
  <section  v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">바카라</h2>-->


    <div class="nbox" style="min-height: 1200px; margin-top: 120px;">
      <!-- <SideRight :g-type="gType"></SideRight> -->
      <div class="contLeft">
        <div class="gameBox" v-if="url">
          <iframe scrolling="no" frameborder="0"
                  style="width: 100%; height: 800px;
              margin:0px 0px 0px 0px; z-index: 1; border: none;"
                  allowFullScreen="true"
                  :src="url">
          </iframe>
        </div>

      </div>

    </div>

  </section>
  <div class="mini-game" v-else style="min-height: 800px; margin-top: 50px;">
    <div class="game-wrap" >
      <iframe scrolling="no" frameborder="0"
              style="width: 100%; height: 800px;
              margin:0px 0px 0px 0px; z-index: 1; border: none;"
              allowFullScreen="true"
              :src="url">
      </iframe>
    </div>
  </div>
</template>

<script>
// import SideRight from '../../../components/Game/Token/SideRight'
import {mapState} from "vuex";

export default {
  name: "Baccarat",
  components: {
    // SideRight
  },
  data: function(){
    return {
      hash: null,
      gType: 'baccarat',
      url: null
    }
  },
  created: function(){
    this.initGame();
    this.goToTop();
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  mounted() {
    const config = this.user.members_detail_setting;
    if (!config.베팅권한.토큰바카라.active) {
      this.$store.dispatch('SET_ERR_MSG', '점검중입니다.');
      this.$store.dispatch('SHOW_MODAL', 'error2');
    }
  },
  methods: {
    initGame: async function(){
      return this.$store.dispatch('REQ_TOKEN_INFO')
          .then(data => {
            const result = data.data;
            this.hash = result.hash;
            this.uid = result.uid;
            this.initUrl()
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    initUrl: function(){
      this.url = 'https://api.tgame365.com/api/';
      this.url += `?gType=${this.gType}`;
      this.url += `&uid=${this.uid}`;
      this.url += `&hash=${this.hash}`;

    },
    goToTop: function(){
      window.scrollTo(0,0);
    },
  }
}
</script>

<style scoped>

</style>
