<template>
  <div class="vfm vfm--inset vfm--fixed" style="z-index: 1000;" v-if="isOpen"><!--v-if-->
    <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none NAV_reg" aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
        <div class="vfm__content pupop_content pupop_registered fadeIn">
            <h5 class="XX" @click="$store.dispatch('INVISIBLE_MODAL', 'signup')">
                <span lass="icon-iconCross"></span>
            </h5>
            <div class="reg relative backface-hidden">
                <div class="topFrame">
                    <div class="top">
                        <img src="../../assets/img/main/main_toplogo.png">
                    </div>
                    <div class="title">
                        <h2>회원가입</h2>
                    </div>
                </div>
                <form @submit.prevent>
                    <div class="upFrame">
                        <div class="up">
                            <div class="input_zone">
                                <div class="leftZone lineUse">
                                    <div class="input-Out-all">
                                        <div class="input-out-frame verifiUse">
                                            <div class="inputFrame verifiUse">
                                                <div class="info">
                                                    <span class="ficon icon-icconMan"></span>
                                                    <div class="txt">ID<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content btnUse"><input
                                                            type="text" name="account"
                                                            placeholder="ID (영문, 숫자 포함 4자 이상)" class="" v-model="signUpForm.userId">
                                                        <div class="checkUse ko-KR-member"><span
                                                                class="icon-icconCHECK"></span>
                                                            <!--<button type="button"-->
                                                            <!--        class="regBtn blueLLineBtn">중복-->
                                                            <!--</button>-->
                                                        </div>
                                                        <div class="hint" style="display: none;">
                                                            <div class="txt">계정등록이 가능합니다.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-out-frame verifiUse">
                                            <div
                                                    class="inputFrame verifiUse">
                                                <div class="info"><span
                                                        class="ficon icon-icconMOBILE"></span>
                                                    <div class="txt">휴대폰 번호<span
                                                    >*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content"><input
                                                            type="text" placeholder="숫자만 입력가능"
                                                            class="" name="mobile" v-model="signUpForm.contact">
                                                        <div class="checkUse">
                                                            <span class="icon-icconCHECK"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame remindUse">
                                            <div
                                                    class="inputFrame verifiUse">
                                                <div class="info"><span
                                                        class="ficon icon-icconPW"></span>
                                                    <div class="txt">비밀번호<span
                                                    >*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="password" placeholder="비밀번호 (영문, 숫자 포함 6자이상)" class=""
                                                            v-model="signUpForm.userPw"
                                                            name="password">
                                                    </div>
                                                    <div class="remind-msg-zone"
                                                         style="display: none;">
                                                        <h5>비밀번호는 아래의 조건이 포함되어야 합니다 :</h5>
                                                        <div class="checkFrame">
                                                            <div class="checkZone">
                                                                <div class="dot">
                                                                    <span class="icon-icconCHECK"></span>
                                                                </div>
                                                                <h5>최소 6자, 최대 16자</h5>
                                                            </div>
                                                            <div class="checkZone">
                                                                <div class="dot">
                                                                    <span class="icon-icconCHECK"></span>
                                                                </div>
                                                                <h5>영숫자 조합</h5>
                                                            </div>
                                                            <div class="checkZone">
                                                                <div class="dot">
                                                                    <span class="icon-icconCHECK"></span>
                                                                </div>
                                                                <h5>(선택)특수기호!@#$%^&amp;*()+_</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div class="input-out-frame">-->
                                        <!--    <div class="inputFrame verifiUse">-->
                                        <!--        <div class="info">-->
                                        <!--            <span class="ficon icon-icconPARTNER"></span>-->
                                        <!--            <div class="txt">통신사<span>*</span></div>-->
                                        <!--        </div>-->
                                        <!--        <div class="input-inner-all">-->
                                        <!--            <div class="input_content btnUse01">-->
                                        <!--                <input type="text" placeholder="통신사 선택" readonly="" class="" name="mobileCarrier">-->
                                        <!--                <div class="checkUse ko-KR-choose">-->
                                        <!--                    <span class="icon-icconCHECK"></span>-->
                                        <!--                    <button type="button" class="regBtn goCarrier blueLLineBtn">선택</button>-->
                                        <!--                </div>-->
                                        <!--            </div>-->
                                        <!--        </div>-->
                                        <!--    </div>-->
                                        <!--</div>-->
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame">
                                            <div class="inputFrame verifiUse">
                                                <div class="info">
                                                    <span class="ficon icon-icconPW"></span>
                                                    <div class="txt">비밀번호 확인<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="password" placeholder="비밀번호 확인 (영문, 숫자 포함 6자이상)" class="" v-model="signUpForm.userPw2" name="confirmPassword">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame verifiUse">
                                            <div class="inputFrame verifiUse">
                                                <div class="info">
                                                    <span class="ficon icon-icconMan"></span>
                                                    <div class="txt">닉네임<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content btnUse"><input
                                                            type="text" name="account"
                                                            placeholder="닉네임" class="" v-model="signUpForm.nickname">
                                                        <div class="checkUse ko-KR-member"><span
                                                                class="icon-icconCHECK"></span>
                                                            <!--<button type="button"-->
                                                            <!--        class="regBtn blueLLineBtn">중복-->
                                                            <!--</button>-->
                                                        </div>
                                                        <div class="hint" style="display: none;">
                                                            <div class="txt">계정등록이 가능합니다.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame">
                                            <div class="inputFrame verifiUse noLine">
                                                <div class="info">
                                                    <span class="ficon icon-icconCALENDAR"></span>
                                                    <div class="txt">생년월일<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="text" placeholder="예시)19760325" class="" name="birthday" v-model="signUpForm.birthday">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-out-frame"></div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame">
                                            <div class="inputFrame verifiUse">
                                                <div class="info">
                                                    <span class="ficon icon-icconMan"></span>
                                                    <div class="txt">예금주<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="text" placeholder="예금주 변경이 불가능합니다" class="" name="name" v-model="signUpForm.bankDepositor">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-out-frame">
                                            <div class="inputFrame verifiUse">
                                                <div class="info">
                                                    <span class="ficon icon-icconBANK"></span>
                                                    <div class="txt">은행명<span>*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content btnUse01">
                                                        <select id="sel01" v-model="signUpForm.bankName">
                                                          <option :value="null">--은행선택--</option>
                                                          <option v-for="(row, index) in bank_name" :key="'bankName' +index" :value="row.name">{{ row.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame verifiUse">
                                            <div class="inputFrame verifiUse">
                                                <div class="info"><span
                                                        class="ficon icon-icconPW"></span>
                                                    <div class="txt">출금 비밀번호<span>*</span>
                                                    </div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="password" placeholder="출금 비밀번호 ( 4-6자리, 숫자만 가능 )" class="" v-model="signUpForm.cashoutPw" name="moneyPassword">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-out-frame verifiUse"></div>
                                    </div>
                                    <div class="input-Out-all">
                                        <div class="input-out-frame">
                                            <div
                                                    class="inputFrame verifiUse">
                                                <div class="info"><span
                                                        class="ficon icon-icconPC"></span>
                                                    <div class="txt">계좌<span
                                                    >*</span></div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="text" placeholder="계좌(계좌을 입력해주세요)" class="" name="bankAccount" v-model="signUpForm.bankAccount">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-out-frame">
                                            <div
                                                    class="inputFrame verifiUse">
                                                <div class="info"><span
                                                        class="ficon icon-icconRECOMMEND"></span>
                                                    <div class="txt">가입코드<span
                                                            style="display: none;">*</span>
                                                    </div>
                                                </div>
                                                <div class="input-inner-all">
                                                    <div class="input_content">
                                                        <input type="text" placeholder="가입코드를 입력하세요" v-model="signUpForm.code" class="" name="parentAccount">
                                                        <input type="text" disabled="" style="display: none;">
                                                    </div>
                                                    <div class="remind-txt" style="margin-left: 15px;">
                                                        *가입코드가 <br>
                                                        기억나지 않는 <br>
                                                        경우 'new' <br>
                                                        기재 바랍니다.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnFrame">
                                <button @click="doSignup" class="btn_p greyBtn">
                                    즉시가입
                                </button>
                                <div class="txtZone">
                                    <a></a>
                                    <a href="javascript:void(0)" class="goLogin">
                                        계정이 있으신가요?<span>로그인하기</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MSG from "@/contants/msg";
import { mapState } from "vuex";

export default {
  name: "Signup",
  data: () => ({
    userId: null,
    userIdMsg: null,
    userPw: null,
    userPw2: null,
    nickname: null,
    nicknameMsg: null,
    code: null,
    codeMsg: null,
    phone: null,
    bankName: null,
    owner: null,
    account: null,
    cashoutPw: null,
    cashoutPw2: null,
    signUpForm: {
      userId: null,
      nickname: null,
      userPw: null,
      contact: null,
      cashoutPw: null,
      bankDepositor: null,
      bankName: null,
      bankAccount: null,
      code: '',
      referrer: '',
    },
    infoAgree: false,
    idPass: false,
    codePass: false,
    nicknamePass: false,
  }),
  watch: {
    'signUpForm.userId': function(val){
      this.userId = val.replace(/[^a-zA-Z0-9]/gi, '')
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      if(reg.exec(val)!==null){
        return this.signUpForm.userId = this.signUpForm.userId.slice(0,-1);
      }
    },
  },
  computed: {
    bank_name: function(){
      return this.$store.getters['BANK_SETTING']
    },
    ...mapState({
      isOpen: state => state.modals.signup,
    }),
  },
  methods: {
    doSignup: function(){
      // if (!this.infoAgree) errMsg = '개인정보 수집 및 이용동의를 체크 해주세요');
      let errMsg = '';
      if (this.signUpForm.userPw !== this.signUpForm.userPw2) errMsg = MSG.error.difPw
      if (!this.signUpForm.userId) errMsg = MSG.error.emptyId;
      if (!this.signUpForm.userPw) errMsg =  MSG.error.emptyPassword;
      if (!this.signUpForm.userPw2) errMsg = MSG.error.emptyPassword;
      if (this.signUpForm.userPw.length <= 7) errMsg = MSG.error.shortPassword;
      if (!this.signUpForm.nickname) errMsg = MSG.error.emptyNick;
      if (!this.signUpForm.code) errMsg = MSG.error.emptyCode;
      if (!this.signUpForm.contact) errMsg = MSG.error.emptyContact;
      if (!this.signUpForm.bankName) errMsg = MSG.error.emptyBank;
      if (!this.signUpForm.bankDepositor) errMsg = MSG.error.emptyDepositor;
      if (!this.signUpForm.bankAccount) errMsg = MSG.error.emptyBankNum;
      if (!this.signUpForm.cashoutPw) errMsg =  MSG.error.emptyWithdrawalPw;
      if (errMsg !== '') return alert(errMsg);
      this.isProcess = true;
      return this.$store.dispatch('SIGNUP', this.signUpForm)
          .then(() => {
            alert(MSG.success.signup);
            this.$router.push({ name: '메인' })
          })
          .catch((error) => {
            alert(error.response.data.error);
            // alert(error.response.data.error);
          })
    },
    checkRefererCode: function(){
      const data = {};
      data.payload = {
        code: this.code,
        siteName: 'newbet'
      };
      this.$store.dispatch('auth/checkCode', data).then(data => {
        if (data.success) {
          this.codePass = true;
          this.codeMsg = null;
        }
        if (!data.success) {
          this.codePass = false;
          this.codeMsg = data.msg;
        }
      })
    },
  },
}
</script>


<style scoped>
</style>
