<template>
  <section class="customer" v-if="!this.$store.state.page.isMobile">
    <ul class="locationList">
      <li>HOME</li>
      <li>자주 묻는 질문</li>
    </ul>
<!--    <h2 class="subTitle">자주 묻는 질문</h2>-->


    <div class="cusbox" style="min-height: 500px;">

      <div class="nbox">
        <SideLink :category="'faq'"></SideLink>


        <div class="cusRight" style="min-height: 500px;">
          <div class="boHeader">
          </div>
          <table class="listTable03" style="margin-top: 62px;">
            <colgroup>
              <col width="120" />
              <col width="" />
              <col width="124" />
              <col width="124" />
            </colgroup>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>글쓴이</th>
              <th>작성일</th>
            </tr>
            <tr v-if="!lists">
              <td colspan="4" style="text-align: center;">
                <div>
                  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              </td>
            </tr>
            <tr v-if="lists && lists.length === 0">
              <td colspan="4">
                작성된 글이 없습니다.
              </td>
            </tr>
            <tr v-if="lists && lists.length > 0" v-for="(row, index) in lists" :key="'customer'+index">
              <td>{{ ((payload.page -1) * payload.size) + (index+1)   }}</td>
              <td class="al"><a @click="movePage(`board/faq/detail/${row.board_seq}`)">{{ row.board_title }}</a></td>
              <td>{{ row.board_author_name }}</td>
              <td>{{ row.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </table>

          <Pagination v-if="pagination" :req-data="reqData" :payload="payload" :pagination="pagination"></Pagination>
        </div>
      </div>

    </div>
  </section>
  <div v-else>
    <div class="header2">
      <h2>FAQ</h2>
    </div>
    <div class="tabs1">
      <a @click="movePage('bbs/c/l')">1:1문의</a>
      <a @click="movePage('bbs/n/l')">공지사항</a>
      <a @click="movePage('board/faq/list')" class="active">FAQ</a>
      <a @click="movePage('bbs/e/l')">이벤트</a>
      <a @click="movePage('board/rule/list')">베팅 규정</a>
    </div>

    <div class="bg-content">
      <div class="notice">
        <ul>
          <li v-if="!lists">
            <div style="text-align: center;">
              <Loading></Loading>
            </div>
          </li>
          <li v-if="lists && lists.length === 0">
            <a class="q">
              <span>
                작성된 글이 없습니다.
              </span>
            </a>
          </li>
          <li v-else v-for="(row, index) in lists" :key="'customer'+index">
            <a @click="toggleArticle(index)" class="q">
              <span>
                {{ row.board_title }}
              </span>
              <em>{{ row.board_regdatetime | formatDate('YYYY-MM-DD') }}</em>
            </a>
            <transition name="fade">
              <div class="cont" v-html="row.board_content" v-if="expandedArticle.indexOf(index)>=0" style="display: block"></div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination01'
import SideLink from '../../../components/Board/SideLink'
import Loading from '../../../components/LoadingGray'

export default {
  name: "Event",
  components: {
    Pagination,
    SideLink,
    Loading
  },
  data: function(){
    return {
      path: 'board/faq/list',
      lists: null,
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null
      },
      expandedArticle: []

    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function(){
      return this.$store.dispatch('auth/post', {path: this.path, data: { payload: this.payload} })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggleArticle: function(index) {
      if(this.expandedArticle.indexOf(index)>=0) {
        this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1);
      }
      else this.expandedArticle.push(index);
    },
  }
}
</script>

<style scoped>

</style>
