<template>
  <div style="margin-top:105px;" v-if="!isMobile">
    <div class="main_content_continer" version="1.1.114">
      <div class="main_content_continer" style="min-height: 1349px;">
        <iframe  v-if="isLoading" allowfullscreen="true" height="1349" id="iFrame"
        webkitallowfullscreen="true" mozallowfullscreen="true" width="100%" :src="url"></iframe>
      </div><!--v-if-->
    </div>
  </div>
  <div v-else style="margin-top: 50px;">
    <div class="main_content_continer" version="1.1.114" style="overflow:hidden; height: 900px;" >
      <iframe  v-if="isLoading" allowfullscreen="true" height="900" id="iFrame"
      mozallowfullscreen="true" width="100%" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
// 데스크탑
// import SideBar from '@/components/Game/Sports/Inplay/SideBar'
// import List from '@/components/Game/Sports/Inplay/InplayList2'
// import NewCart from '@/components/Betting/InplayCart'

// 모바일
// import MCart from '@/components/Betting/MInplayCart'
// import MList from '@/components/Game/Sports/Inplay/MInplayList'

//공통
// import Loading from '@/components/Loading'
// import MSG from '@/contants/msg'
import {mapState} from "vuex";
// import moment from "moment";

export default {
  name: "Inplay",
  props: ['isMobile'],
  // components: {
  //   // SideBar,
  //   // List,
  //   // NewCart,
  //   // MCart,
  //   // MList,
  //   // Loading
  //   // RingLoader,
  // },
  data: function () {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    authToken(){
      if (!this.user) return null;
      return JSON.parse(localStorage.getItem('token')).jwt_token
    },
    url(){
      // let url = 'http://127.0.0.1:3080/';
      let url = 'https://sport-desktop.nb-123.com/#/sport/inplay';
      if (this.isMobile) url = 'https://sport-mobile.nb-123.com/#/sport/inplay';
      let siteName, authToken;
      siteName = 'newbet';
      authToken = this.authToken;
      if (this.user){
        siteName = this.user.members_sitename;
      }
      url += `?siteName=${siteName}&authToken=${authToken}`;

      return url
    }
  },
  mounted() {
    setTimeout(()=>{ this.isLoading = !this.isLoading;}, 2000);
  }
  // watch: {
  //   selectedGame(val) {
  //     if (!val) {
  //       this.lists = null;
  //       this.initFolder();
  //     }
  //   },
  // },
  // mounted: function () {
  //   setTimeout(() => {
  //     if (!this.$socket.connected) {
  //       alert(MSG.error.socketNotConnected);
  //       this.$socket.client.connect();
  //       this.initFolder()
  //     }
  //   }, 2000)
  //   window.addEventListener('scroll', this.handleScroll)
  //   if (this.user) {
  //     const config = this.user.members_detail_setting;
  //     if (!config.베팅권한.인플레이.active) {
  //       alert(MSG.error.authorization);
  //       this.$router.push({name: '메인'});
  //     }
  //   }
  //   this.initFolder()
  //   this.reqWait();
  //   this.interval = setInterval(this.initFolder2, 20000);
  //   this.interval2 = setInterval(this.checkInitTime, 1000);
  // },
  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.handleScroll)
  //   clearInterval(this.interval);
  //   clearInterval(this.interval2);
  // },
  // methods: {
  //   checkInitTime() {
  //     const now = moment().unix();
  //     if (this.initTime && now - this.initTime > 180) {
  //       alert('화면 로딩후 일정시간이 지나 인플레이 데이터의 갱신이 필요합니다.');
  //       this.initFolder();
  //       this.$store.dispatch('ALL_INPLAY_CLEAR_ITEM');
  //     }
  //   },
  //   handleScroll: function () {
  //     const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
  //     if (currentScrollPosition > 200) {
  //       this.isScrollDown = true;
  //     } else {
  //       this.isScrollDown = false;
  //     }
  //   },
  //   // 경기요청
  //   initFolder(payload) {
  //     this.isLoading = false;
  //     this.$store.dispatch('GET_INPLAY_LIST', {payload}).then((result) => {
  //       if (result.data.success) {
  //         this.isLoading = true;
  //         this.lists = this.originList;
  //         this.initTime = moment().unix();
  //       } else {
  //         console.log('인플레이 경기요청 에러', result)
  //         this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
  //       }
  //     })
  //   },
  //   // 경기요청
  //   initFolder2(payload) {
  //     this.$store.dispatch('GET_INPLAY_LIST', {payload}).then((result) => {
  //       if (result.data.success) {
  //         this.lists = this.originList;
  //       } else {
  //         console.log('인플레이 경기요청2 에러')
  //         this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
  //       }
  //     })
  //   },
  //   reqWait() {
  //     this.$store.dispatch('GET_INPLAY_WAIT_LIST', {payload: {}})
  //   },
  //   // 게임종목
  //   changeCategory: function (category) {
  //     this.category = category;
  //     if (category === '') {
  //       return this.lists = this.originList;
  //     }
  //     this.lists = this.originList.filter(e => e.sportSeq === category);
  //   },
  //   // 베팅카트에 담기
  //   addCart: function (bets, folders, game) {
  //     if (this.user) {
  //       const config = this.user.members_detail_setting;
  //       if (!config.베팅권한.인플레이.active) return alert(MSG.error.authorization);
  //     } else return alert(MSG.error.isNotLogin);

  //     const cartItem = {
  //       gameId: game.id,
  //       gameSeq: game.seq,
  //       betsSeq: bets.seq,
  //       betsStatus: bets.status,
  //       betsId: bets.id,
  //       betsName: bets.name,
  //       betsUpdated: false,
  //       betsPrevOdds: bets.price,
  //       sportsId: game.sportId,
  //       foldersId: folders.id,
  //       foldersSeq: folders.seq,
  //       foldersType: folders.type,
  //       odds: bets.price,
  //       line: bets.line ? bets.line : null,
  //       leagueNameKr: game.league.nameKr,
  //       leagueNameEn: game.league.nameEn,
  //       leagueNameIcon: game.league.icon,
  //       sportNameKr: game.sport.nameKr,
  //       sportNameEn: game.sport.nameEn,
  //       sportNameIcon: game.sport.icon,
  //       homeNameKr: game.homeTeam.nameKr,
  //       homeNameEn: game.homeTeam.nameEn,
  //       awayNameKr: game.awayTeam.nameKr,
  //       awayNameEn: game.awayTeam.nameEn,
  //       marketsId: folders.marketId,
  //       isCheck: folders.market.isCheck,
  //       marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
  //       marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
  //       startTime: game.startTime,
  //       odds1: null,
  //       odds2: null,
  //       odds3: null,
  //       betType: '인플레이',
  //       detailType: null,
  //       currentPeriod: game.currentPeriod,
  //       currentTime: `${game.subResult}`,
  //       homeScore: game.homeResult,
  //       awayScore: game.awayResult,
  //       penalty: null,
  //       maxAmount: game.league.maxAmount,
  //     }

  //     if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
  //       if (folders.periodSequence !== '0') {
  //         cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
  //         cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
  //       }
  //     }

  //     if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
  //       cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
  //     }

  //     if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
  //       cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
  //     }
  //     this.$store.dispatch('ADD_CART_INPLAY_ITEM', cartItem)
  //   },
  //   // 카트내역 체크해서 표시
  //   checkCart: function (bets, type) {
  //     if (type === '보너스' || !bets) {
  //       return null;
  //     } else {
  //       let index = this.cartItem.find(item => item.betsSeq === bets.seq)
  //       return !!index;
  //     }
  //   },

  //   moveDetail: function (game) {
  //     this.$router.push({path: '/sport/live/d', query: {gid: game.id}})
  //   },
  //   uniq: function (array) {
  //     return Array.from(new Set(array));
  //   },
  //   moveBack: function () {
  //     this.selectedGame = null;
  //   },
  //   filterByGames: function (sortType, target) {
  //     this.selectedGame = null;
  //     if (sortType === 'all') {
  //       return this.lists = this.originList;
  //     }
  //     if (sortType === 'league') {
  //       this.lists = this.originList.filter(e => e.leagueSeq === target);
  //     }

  //     if (sortType === 'date') {
  //       this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
  //     }

  //     if (sortType === 'sport') {
  //       this.lists = this.originList.filter(e => e.sportSeq === target);
  //     }

  //     if (sortType === 'team') {
  //       this.lists = this.originList.filter(e =>
  //           e.homeTeam.nameEn.indexOf(target) >= 0 ||
  //           e.homeTeam.nameKr.indexOf(target) >= 0 ||
  //           e.awayTeam.nameEn.indexOf(target) >= 0 ||
  //           e.awayTeam.nameKr.indexOf(target) >= 0
  //       );
  //     }
  //   },
  //   toggleStick: function () {
  //     this.sticky = !this.sticky;
  //   },
  //   /* 서버 설정에 따라서 경기 베팅을 막는다.
  //   *  True
  //   * */
  //   checkBlockGame: function (game) {
  //     let blockConfig = {};
  //     if (game.sportId === 1) {
  //       blockConfig = this.blockSetting['football'];
  //       if (game.subResult?.indexOf('HT') >= 0) return false;
  //       if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //     }
  //     if (game.sportId === 2) {
  //       blockConfig = this.blockSetting['hockey'];
  //       let remainTime = game.remainTime;
  //       const time = game.subResult?.split(' ');
  //       if (time) remainTime = `00:${time[time.length - 1]}`;
  //       if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //     }
  //     if (game.sportId === 3) {
  //       blockConfig = this.blockSetting['basketball'];
  //       let remainTime = game.remainTime;
  //       let currentPeriod = 1;
  //       const time = game.subResult?.split(' ');
  //       if (time) {
  //         remainTime = `00:${time[time.length - 1]}`;
  //         currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
  //         console.log(currentPeriod);
  //       }
  //       // console.log(remainTime)
  //       console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
  //       if (remainTime === '00:00:00') {
  //         if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
  //         if (currentPeriod >= 3) return;
  //       } else {
  //         if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
  //           if (!remainTime) return true;
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
  //           if (!remainTime) return true;
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
  //           if (!remainTime) return true;
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
  //           if (!remainTime) return true;
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (currentPeriod > 4) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }
  //     }
  //     if (game.sportId === 5) {
  //       blockConfig = this.blockSetting['volleyball'];
  //       const name = `set${game.currentPeriod}Score`;
  //       const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
  //       const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
  //       if (Number(game.currentPeriod) === 1) {
  //         if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
  //         if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }

  //       if (Number(game.currentPeriod) === 2) {
  //         if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

  //         if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }

  //       if (Number(game.currentPeriod) === 3) {
  //         if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

  //         if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }

  //       if (Number(game.currentPeriod) === 4) {
  //         if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

  //         if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }

  //       if (Number(game.currentPeriod) === 5) {
  //         if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //         if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
  //           this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //           return true;
  //         }
  //       }

  //     }
  //     if (game.sportId === 6) {
  //       blockConfig = this.blockSetting['americanFootball'];
  //       let remainTime = game.remainTime;
  //       const time = game.subResult?.split(' ');
  //       if (time) remainTime = `00:${time[time.length - 1]}`;
  //       if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //       if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
  //         this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
  //         return true;
  //       }
  //     }
  //     if (game.sportId === 11) {
  //       blockConfig = this.blockSetting['baseball'];
  //       const detailResult = game.subResult?.split(' ');
  //       const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
  //       if (periodCnt > Number(blockConfig.first)) return true;
  //     }

  //     return false;
  //   },
  // },
}
</script>

