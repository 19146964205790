<template>
  <div class="vfm vfm--inset vfm--fixed"
       style="z-index: 1000;" v-if="isOpen"><!--v-if-->
    <div
        class="vfm__container vfm--absolute vfm--inset vfm--outline-none flex-center bg-[rgba(0,0,0,0.8)]"
        aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
      <div class="vfm__content">
        <div
            class="modal-open-by-scale relative c-text-asInverse-02 rd-12px transition-all duration-200 h-90vh label-regular bg-bg-asInverse-10 w-560px">
          <div class="absolute cursor-pointer right-20px top-17px">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" @click="$store.dispatch('INVISIBLE_MODAL', 'deposit')"
                 height="32" viewBox="0 0 32 32" size="17px" class="cursor-pointer fill-inverse-09"><title>
              icon-close</title>
              <path
                  d="M3.2 32l-3.2-3.2 12.8-12.8-12.8-12.8 3.2-3.2 12.8 12.8 12.8-12.8 3.2 3.2-12.8 12.8 12.8 12.8-3.2 3.2-12.8-12.8-12.8 12.8z"></path>
            </svg>
          </div>
          <div class="p-20px text-16px font-bold">입금</div>
          <div class="relative mb-12px px-32px">
            <ul
                class="flex border-1 border-solid c-primary w-492px border-primary">
              <li
                  @click="toggleList('deposit')"
                  :class="{'tab-active-btn': mType === 'deposit', 'tab-inactive-btn': mType !== 'deposit'}"
                  class="flex-center grow h-40px lh-20px border-primary border-solid flex-1 border-l-1 border-r-1">
                법정화폐
              </li>
              <li
                  :class="{'tab-active-btn': mType !== 'deposit', 'tab-inactive-btn': mType === 'deposit'}"
                  @click="toggleList('list')"
                  class="flex-center grow h-40px lh-20px flex-1">입금내역
              </li>
            </ul>
          </div>
          <div
              v-if="mType ==='deposit'"
              class="px-32px border-t-1 h-67vh overflow-y-auto overflow-x-hidden scrollbar scrollbar-w-4px scrollbar-track-color-transparent scrollbar-thumb-radius-4px scrollbar-rounded">
            
            <div class="flex items-center justify-end cursor-pointer mb-4px"><h4
                class="mr-4px c-alert text-14px font-500">확인/필독사항</h4>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32"
                   size="16px" class="fill-alert"><title>icon-alert</title>
                <path
                    d="M25.72 5.368c-5.873-5.873-15.42-5.903-21.293 0-5.873 5.873-5.903 15.42 0 21.293 5.873 5.873 15.42 5.903 21.293 0 5.873-5.873 5.873-15.42 0-21.293zM17.167 25.547c0 0.241-0.181 0.422-0.422 0.422h-3.373c-0.241 0-0.422-0.181-0.422-0.422v-12.469c0-0.241 0.181-0.422 0.422-0.422h3.373c0.241 0 0.422 0.181 0.422 0.422v12.469zM15.059 10.91c-1.355 0-2.44-1.084-2.44-2.44s1.084-2.44 2.44-2.44c1.355 0 2.44 1.084 2.44 2.44 0 1.325-1.084 2.44-2.44 2.44z"></path>
              </svg>
            </div>
<!--            <div class="text-14px font-700 mb-8px c-text-asInverse-02!">입금 화폐유형</div>-->
<!--            <div-->
<!--                class="rd-3px px-10px py-7px relative mt-10px h-44px dropdown-base mb-24px hover:b-inverse-01! cursor-unset!">-->
<!--              <div class="flex items-center">-->
<!--                <svg aria-hidden="true" alt="icon-fiat" class="mr-6px" style="width: 24px; height: 24px;">-->
<!--                  <use xlink:href="#icon-icon-fiat" fill="#333"></use>-->
<!--                </svg>-->
<!--                KRW-->
<!--              </div>-->
<!--              <div class="flex items-center">-->
<!--                <svg aria-hidden="true" alt="icon-fiat" class="mr-6px" style="width: 24px; height: 24px;">-->
<!--                  <use xlink:href="#icon-icon-fiat" fill="#333"></use>-->
<!--                </svg>-->
<!--                0-->
<!--              </div>-->
<!--            </div>-->
            <div class="text-14px font-700 mb-8px">입금 계좌 확인 바랍니다.</div>
            <div class="flex-between gap-x-8px mb-16px">
              <div class="rd-3px relative h-44px input-outer-base w-378px">
                <div class="ml-12px">{{ bankName }}  {{ account }} {{ ownerName }}</div>
              </div>
              <button class="h-44px grow rd-3px btn-base btn-primary" @click="reqAccount">확인</button>
            </div>
            <div class="text-14px font-700 mb-8px">보너스 선택</div>
            <div class="flex-between gap-x-8px mb-16px " v-for="b in bonus" :key="'rolling_bonus' + b.seq" @click="chargeForm.rollingSeq = b.seq">
              <div class="rd-3px relative h-44px input-outer-base w-378px" :class="{'bg-primary': chargeForm.rollingSeq === b.seq}">
                <div class="ml-12px">
                  <div>{{ b.name }} ({{ b.description }})</div>
                </div>
              </div>
            </div>
            <div class="text-14px font-700 mb-8px">입금신청 금액</div>
            <div class="flex-between gap-x-8px mb-8px">
              <div class="rd-3px relative h-44px w-full pl-10px input-outer-base justify-between">
                <form class="flex items-center" autocomplete="off">
                  <svg aria-hidden="true" alt="icon-fiat" class="mr-6px" style="width: 24px; height: 24px;">
                    <use xlink:href="#icon-icon-fiat" fill="#333"></use>
                  </svg>
                  <span data-pc-name="inputnumber" data-pc-section="root">
                    <input role="spinbutton"
                           class="w-180px w-180px! text-14px font-500 input-base"
                           aria-valuenow="0"
                           disabled=""
                           type="number"
                           v-model="chargeForm.amount"
                     >
                    </span></form>
                <div class="flex items-center">
                  <button class="px-10px rd-3px h-27px text-13px font-500 mr-10px btn-base btn-secondary" @click="resetAmount">초기화</button>
                </div>
              </div>
            </div>
            <div class="flex-end flex-col items-end text-14px font-500 c-primary gap-4px"><span>보너스</span><span
                style="display: none;">일일 최대한도 : <span>제한없음</span></span></div>
            <div class="text-14px font-500 mb-16px c-text-asInverse-03 mb-8px!">{{chargeForm.amount}} - {{ chargeForm.amount }} KRW</div>
            <div class="grid btn-group grid-cols-3 money gap-y-10px gap-x-12px">
              <div disabled="false"
                   @click="changeAmount(10000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                1 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(30000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                3 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(50000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                5 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(100000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                10 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(500000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                50 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(1000000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                100 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(5000000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                500 만원
              </div>
              <div disabled="false"
                   @click="changeAmount(10000000)"
                   class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                1000 만원
              </div></div>
            <div class="bg-inverse-01 my-16px divider"></div>
            <div class="rd-3px mb-20px text-14px font-700 py-14px px-10px bg-bg-asInverse-01 bg-op-20 mt-16px">최소 ₩ 0 이상
              입금가능합니다. ₩ 10,000 단위로 신청해주세요.
            </div>
            <div class="absolute w-full flex-col left-0 px-32px bottom-0 pt-20px pb-32px">
              <button class="w-496px h-44px rd-3px btn-base btn-primary" @click="processDeposit">입금신청</button>
            </div>
          </div>
          <div class="px-32px border-t-1 h-67vh" v-else>
            <!--            <h4>-->
            <!--              <div class="mb-8px font-bold">화폐 지갑</div>-->
            <!--            </h4>-->
            <!--            <div-->
            <!--                class="relative h-44px border-rd-3px p-10px gap-10px dropdown-base hover:b-primary!  cursor-pointer"-->
            <!--                data-pc-name="dropdown" data-pc-section="root" id="pv_id_3"><span-->
            <!--                class="w-full flex justify-between items-center" tabindex="0" role="combobox" aria-haspopup="listbox"-->
            <!--                aria-expanded="false" aria-controls="pv_id_3_list" aria-disabled="false" data-pc-section="input"-->
            <!--                aria-label="전체"><div class="flex-center"><svg version="1.1" xmlns="http://www.w3.org/2000/svg"-->
            <!--                                                              width="32" height="32" viewBox="0 0 32 32"-->
            <!--                                                              class="fill-primary mr-2"-->
            <!--                                                              size="24px"><title>icon_currency</title><path-->
            <!--                d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z"></path><path-->
            <!--                fill="#fff"-->
            <!--                d="M9.778 6.667h2.333c1.718 0 3.111 1.393 3.111 3.111v2.333c0 1.718-1.393 3.111-3.111 3.111h-2.333c-1.718 0-3.111-1.393-3.111-3.111v-2.333c0-1.718 1.393-3.111 3.111-3.111z"></path><path-->
            <!--                fill="#fff"-->
            <!--                d="M19.889 6.667h2.333c1.718 0 3.111 1.393 3.111 3.111v2.333c0 1.718-1.393 3.111-3.111 3.111h-2.333c-1.718 0-3.111-1.393-3.111-3.111v-2.333c0-1.718 1.393-3.111 3.111-3.111z"></path><path-->
            <!--                fill="#fff"-->
            <!--                d="M9.778 16.778h2.333c1.718 0 3.111 1.393 3.111 3.111v2.333c0 1.718-1.393 3.111-3.111 3.111h-2.333c-1.718 0-3.111-1.393-3.111-3.111v-2.333c0-1.718 1.393-3.111 3.111-3.111z"></path><path-->
            <!--                fill="#fff"-->
            <!--                d="M19.889 16.778h2.333c1.718 0 3.111 1.393 3.111 3.111v2.333c0 1.718-1.393 3.111-3.111 3.111h-2.333c-1.718 0-3.111-1.393-3.111-3.111v-2.333c0-1.718 1.393-3.111 3.111-3.111z"></path></svg><div-->
            <!--                class="font-bold">전체</div></div></span>&lt;!&ndash;&ndash;&gt;-->
            <!--              <div class="c-inverse-09" data-pc-section="trigger">-->
            <!--                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"-->
            <!--                     aria-hidden="true" class="" data-pc-section="dropdownicon">-->
            <!--                  <path-->
            <!--                      d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z"-->
            <!--                      fill="currentColor"></path>-->
            <!--                </svg>-->
            <!--              </div>&lt;!&ndash;&ndash;&gt;</div>-->
            <!--            <div class="mt-16px"><h4 class="font-bold">일자조회</h4>-->
            <!--              <div data-v-a3e183b3="" class="flex items-center gap-10px my-8px">-->
            <!--                <div data-v-a3e183b3=""-->
            <!--                     class="dp__main dp__theme_light b-1px b-solid b-inverse-01 rd-3px bg-bg-asInverse-13"-->
            <!--                     data-datepicker-instance="" type="datetime" editable="false">-->
            <!--                  <div>&lt;!&ndash;&ndash;&gt;-->
            <!--                    <div class="dp__input_wrap">&lt;!&ndash;&ndash;&gt;<input-->
            <!--                        class="dp__pointer dp__input_readonly dp__input dp__input_reg focus:shadow-none!"-->
            <!--                        inputmode="none" placeholder="" autocomplete="off" aria-label="Datepicker input">-->
            <!--                      <div>&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</div>&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span data-v-a3e183b3="">~</span>-->
            <!--                <div data-v-a3e183b3=""-->
            <!--                     class="dp__main dp__theme_light b-1px b-solid b-inverse-01 rd-3px bg-bg-asInverse-13"-->
            <!--                     data-datepicker-instance="" type="datetime" editable="false">-->
            <!--                  <div>&lt;!&ndash;&ndash;&gt;-->
            <!--                    <div class="dp__input_wrap">&lt;!&ndash;&ndash;&gt;<input-->
            <!--                        class="dp__pointer dp__input_readonly dp__input dp__input_reg focus:shadow-none!"-->
            <!--                        inputmode="none" placeholder="" autocomplete="off" aria-label="Datepicker input">-->
            <!--                      <div>&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</div>&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="flex items-center gap-8px">-->
            <!--                <button-->
            <!--                    class="bg-primary! c-text-asInverse-05! h-44px flex-1 b-solid b-inverse-01! rd-3px font-bold cursor-pointer b-1px! bg-bg-asInverse-13 hover:bg-bg-asInverse-09 hover:c-text-asInverse-05">-->
            <!--                  오늘-->
            <!--                </button>-->
            <!--                <button-->
            <!--                    class="h-44px flex-1 b-solid b-inverse-01! rd-3px font-bold cursor-pointer b-1px! bg-bg-asInverse-13 hover:bg-bg-asInverse-09 hover:c-text-asInverse-05">-->
            <!--                  1 주일-->
            <!--                </button>-->
            <!--                <button-->
            <!--                    class="h-44px flex-1 b-solid b-inverse-01! rd-3px font-bold cursor-pointer b-1px! bg-bg-asInverse-13 hover:bg-bg-asInverse-09 hover:c-text-asInverse-05">-->
            <!--                  2 주일-->
            <!--                </button>-->
            <!--                <button-->
            <!--                    class="h-44px flex-1 b-solid b-inverse-01! rd-3px font-bold cursor-pointer b-1px! bg-bg-asInverse-13 hover:bg-bg-asInverse-09 hover:c-text-asInverse-05">-->
            <!--                  1 개월-->
            <!--                </button>-->
            <!--                <button-->
            <!--                    class="h-44px flex-1 b-solid b-inverse-01! rd-3px font-bold cursor-pointer b-1px! bg-bg-asInverse-13 hover:bg-bg-asInverse-09 hover:c-text-asInverse-05">-->
            <!--                  3 개월-->
            <!--                </button>-->
            <!--              </div>-->
            <!--              <button class="w-full h-44px mt-16px rd-3px btn-base btn-primary">검색</button>-->
            <!--            </div>-->
            <div class="h-1px bg-inverse-01 my-16px"></div>
            <div class="">
              <div
                  class="overflow-y-scroll scrollbar scrollbar-w-4px scrollbar-track-color-transparent scrollbar-thumb-radius-4px scrollbar-rounded">
                <div>
                  <div class="listZone history_content noData">
                    <table>
                      <tbody>
                      <tr>
                          <th
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            신청금액
                          </th>
                          <th
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            신청시간
                          </th>
                          <th
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            완료시간
                          </th>
                          <th
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            처리상태
                          </th>
                        </tr>
                        <tr v-for="row in lists" :key="'cash' + row.cash_seq">
                          <td
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            {{ row.cash_amount | makeComma }}
                          </td>
                          <td
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            {{ row.cash_regdatetime| formatDate('YYYY-MM-DD HH:mm:ss') }}
                          </td>
                          <td
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            {{ row.cash_done_datetime| formatDate('YYYY-MM-DD HH:mm:ss') }}
                          </td>
                          <td
                            class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            {{ row.cash_status }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="b-1px b-solid b-inverse-01" v-if="lists.length === 0">
                      <div class="" style="">
                        <div class="noDataFrame">
                          <div class="pic"><img src="@/assets/images/noData.png" alt="no-data"></div>
                          <h2>데이터 없음</h2>
                          <h3>Sorry, Check no data</h3></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                  v-if="pagination"
                  :payload="payload"
                  :pagination="pagination"
                  :req-data="reqData"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
// import Loading from '../components/Loading'
import {mapState} from "vuex";
import MSG from '@/contants/msg'
import Pagination from '@/components/Pagination01'

export default {
  name: "Deposit",
  props: ['isMobile'],
  components: {
    // Loading,
    Pagination
    // SideBox
  },
  data: function () {
    return {
      amount: 0,
      account: '',
      ownerName: '',
      bankName: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      chargeForm: {
        amount: 0,
        serviceType: 'n',
        code: null,
        rollingSeq: null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '입금',
        detailType: '캐시',
      },
      bonus: [],
      showCodeText: null,
      pagination: {},
      mType: 'deposit'
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.modals.deposit,
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  watch: {
    isOpen() {
      this.reqData()
      this.reqRollingBonus();
    }
  },
  methods: {
    reqData: function () {
      this.$store.dispatch('DEPOSIT_HISTORY', {payload: this.payload})
          .catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    // 충전 금액 변경
    changeAmount: function (amount) {
      this.chargeForm.amount += amount;
    },
    resetAmount: function () {
      this.chargeForm.amount = 0;
    },
    // 충전신청
    processDeposit: function () {
      if (this.chargeForm.amount < 10000) return alert(MSG.error.minProcessAmount);
      if (this.showCodeText) return alert('유효한 코드를 입력해주세요.');
      this.processing = true;
      this.$store.dispatch('PROCESS_DEPOSIT', {payload: this.chargeForm})
          .then(() => {
            this.processing = false;
            alert(MSG.success.deposit);
          })
          .catch((err) => {
            this.processing = false;
            console.log(err);
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })

    },
    amountRound: function () {
      let result = Math.floor(this.chargeForm.amount / 1000)
      this.chargeForm.amount = result * 1000
    },
    // 계좌번호 확인
    reqAccount: function () {
      return this.$store.dispatch('REQ_ACCOUNT')
          .then(data => {
            const result = data.data;
            this.account = result.payload.bank.account;
            this.ownerName = result.payload.bank.ownerName;
            this.bankName = result.payload.bank.bankName;
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    reqRollingBonus: function() {
      return this.$store.dispatch('REQ_ROLLING_BONUS')
          .then(data => {
            const result = data.data;
            this.bonus = result.payload.bonus;
          }).catch(err => {
            alert( err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    toggleReq: function () {
      this.showReq = !this.showReq;
    },
    toggleDescription: function () {
      this.showDescription = !this.showDescription;
    },
    toggleList: function (type) {
      this.mType = type;
    },
    checkBonusCode: function () {
      if (!this.chargeForm.code || this.chargeForm.code === '') return this.showCodeText = null;
      this.$store.dispatch('CHECK_DEPOSIT_BONUS_CODE', {payload: this.chargeForm})
          .then((data) => {
            const result = data.data;
            if (!result.success) this.showCodeText = result.msg;
            if (result.success) {
              this.chargeForm.serviceType = 'y';
              this.showCodeText = null;
            }
          })
    },

  }
}
</script>


<style scoped>
.vfm--inset {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.vfm--absolute {
  position: absolute;
}

.vfm--fixed {
  position: fixed;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.5px 16px
}

.reciprocal {
  color: red;
  font-size: 18px;
  font-weight: 700;
  text-align: center
}

.dp__input_wrap .dp__input {
  border-bottom: none
}

.dp__input_wrap input {
  cursor: default;
  border-bottom: none !important
}

.dp__month_year_col_nav {
  background: none
}

.dp__input_wrap input {
  height: 44px;
  margin: 0;
  text-align: center
}

.dp__input_wrap .dp__input {
  width: 100%
}

.dp__time_col button:focus {
  background-color: transparent !important
}

.dp__month_year_wrap button:focus {
  background-color: transparent !important
}

.main_content_wrap_notice .listZone table tr th {
  position: initial !important
}

.main_content_wrap_notice .listZone table tr td {
  padding: 0 !important
}

.noDataFrame {
  padding: 24px 78px !important
}

table {
  border-radius: 0
}

table tr th {
  position: initial !important
}

.pic {
  display: flex;
  justify-content: center
}

.pic img {
  width: 70% !important
}

table tr th:after, table tr th + th:before, table tr th:first-child:before {
  background-color: unset
}

table tr th:first-child:before {
  bottom: auto
}

[data-v-68591159] .pager_block {
  margin-top: 27px !important;
  float: none !important
}

[data-v-68591159] .pager_block .page-list {
  margin: 0 auto !important
}

[data-v-68591159] table tr th + th:before {
  height: 0 !important
}

[data-v-5d97e089] .down ul {
  margin-left: 25px
}

[data-v-5d97e089] .down li {
  list-style: disc
}

[data-v-5d97e089] .down li:not(:last-child) {
  line-height: 28px
}

.down ul {
  margin-left: 25px
}

.down li {
  list-style: disc
}

.down li:not(:last-child) {
  line-height: 28px
}

.v-enter-active {
  transition: all .2s cubic-bezier(.37, 0, .63, 1)
}

.v-enter-from, .v-leave-to {
  transform: translate(-70px);
  opacity: 0
}

.perpage-text-color span {
  color: #fff !important
}

</style>
