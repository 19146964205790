<template>
  <div class="vfm vfm--inset vfm--fixed" style="z-index: 1000;" v-if="isOpen"><!--v-if-->
    <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none flex-center"
         aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
      <div class="vfm__content">
        <div
            class="absolute top-0 right-0 bottom-0 left-0 pointer-events-none z--1 bg-grayscale-000 bg-op-70!"></div>
        <div class="relative rd-10px overflow-hidden bg-bg-asInverse-10">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
               @click="$store.dispatch('INVISIBLE_MODAL', 'withdrawal')"
               viewBox="0 0 32 32" size="17px"
               class="absolute right-20px top-17px mb-16px cursor-pointer fill-inverse-09"><title>icon-close</title>
            <path
                d="M3.2 32l-3.2-3.2 12.8-12.8-12.8-12.8 3.2-3.2 12.8 12.8 12.8-12.8 3.2 3.2-12.8 12.8 12.8 12.8-3.2 3.2-12.8-12.8-12.8 12.8z"></path>
          </svg>
          <section class="w-full pb-12px pt-20px px-32px">
            <div class="relative w-full flex-start h-43px">
              <div class="mb-16px c-text-asInverse-02 fw-700 text-16px mx--12px">출금</div>
            </div>
            <div class="w-full flex justify-center text-center h-52px items-start">
              <ul class="flex w-full c-primary">
                <li
                    @click="toggleList('withdrawal')"
                    :class="{'tab-active-btn': mType === 'withdrawal', 'tab-inactive-btn': mType !== 'withdrawal'}"
                    class=" flex-1 b-y-1px b-l-1px b-solid b-primary px-19px py-10px gap-10px h-40px lh-20px">
                  <span>법정화폐</span></li>
                <li
                    @click="toggleList('list')"
                    :class="{'tab-active-btn': mType !== 'withdrawal', 'tab-inactive-btn': mType === 'withdrawal'}"
                    class=" flex-1 b-1px b-solid b-primary px-19px py-10px gap-10px h-40px lh-20px">
                  <span>출금내역</span></li>
              </ul>
            </div>
          </section>
          <section
              v-if="mType === 'withdrawal'"
              class="bg-bg-asInverse-10 px-32px w-560px h-78vh overflow-y-auto overflow-x-hidden scrollbar scrollbar-w-4px scrollbar-track-color-transparent scrollbar-thumb-radius-4px scrollbar-rounded">
            
            <div class="bg-bg-asInverse-10 relative">
              <section class="flex justify-end mb-4px">
                <div class="flex items-center cursor-pointer c-alert gap-8px"><h4>
                  확인/필독사항</h4>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="32"
                       viewBox="0 0 30 32" size="16px" class="fill-alert"><title>icon-alert</title>
                    <path
                        d="M25.72 5.368c-5.873-5.873-15.42-5.903-21.293 0-5.873 5.873-5.903 15.42 0 21.293 5.873 5.873 15.42 5.903 21.293 0 5.873-5.873 5.873-15.42 0-21.293zM17.167 25.547c0 0.241-0.181 0.422-0.422 0.422h-3.373c-0.241 0-0.422-0.181-0.422-0.422v-12.469c0-0.241 0.181-0.422 0.422-0.422h3.373c0.241 0 0.422 0.181 0.422 0.422v12.469zM15.059 10.91c-1.355 0-2.44-1.084-2.44-2.44s1.084-2.44 2.44-2.44c1.355 0 2.44 1.084 2.44 2.44 0 1.325-1.084 2.44-2.44 2.44z"></path>
                  </svg>
                </div>
              </section>
              <div class="flex flex-col gap-12px">
                <div class="w-full h-73px">
                  <span class="relative font-700 text-14px c-text-asInverse-02">은행명</span>
                  <div
                      class="rd-3px h-44px p-10px mt-8px input-outer-base input-outer-base-disabled"><span
                      class="font-500 text-14px c-text-asInverse-03">{{ user.members_cashout_bankname }}</span></div>
                </div>
                <div class="w-full h-73px"><span
                    class="relative font-700 text-14px c-text-asInverse-02">계좌</span>
                  <div
                      class="p-10px rd-3px h-44px mt-8px input-outer-base input-outer-base-disabled"><span
                      class="font-500 text-14px c-text-asInverse-03">{{ user.members_cashout_account }}</span></div>
                </div>
                <div class="w-full h-73px"><span
                    class="relative font-700 text-14px c-text-asInverse-02">예금주</span>
                  <div
                      class="p-10px rd-3px h-44px mt-8px input-outer-base input-outer-base-disabled"><span
                      class="font-500 text-14px c-text-asInverse-03">{{ user.members_cashout_owner }}</span></div>
                </div>
                <div class="w-full h-73px">
                  <div class="mb-8px flex-between"><span
                      class="font-700 text-14px c-text-asInverse-02">출금 금액</span>
                    <p class="flex-center gap-4px"><span
                        class="font-500 c-text-asInverse-03 text-13px">최소 출금액 : </span>
                      <svg aria-hidden="true" style="width: 14px; height: 14px;">
                        <use xlink:href="#icon-icon-fiat" fill="#333"></use>
                      </svg>
                      <span class="font-500 text-13px c-text-asInverse-03">10,000 KRW</span></p>
                  </div>
                  <div
                      class="px-10px rd-3px h-44px input-outer-base py-5px input-outer-base-disabled">
                    <form class="flex-center w-full" autocomplete="off">
                      <svg aria-hidden="true" class="mr-8px" style="width: 24px; height: 24px;">
                        <use xlink:href="#icon-icon-fiat" fill="#333"></use>
                      </svg>
                      <span class="w-full relative" data-pc-name="inputnumber"
                            data-pc-section="root">
                        <input role="spinbutton" class="text-13px font-500 m-0! input-base" v-model="exchangeForm.amount" type="number">
                      </span>
                    </form>
                    <button class="px-10px rd-3px w-56px h-27px py-4px btn-base btn-secondary"><span
                        class="text-13px font-500 whitespace-nowrap" @click="resetAmount">초기화</span></button>
                  </div>
                </div>
                <div class="grid btn-group grid-cols-3 money gap-y-10px gap-x-12px">
                  <div disabled="false"
                       @click="changeAmount(10000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    1 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(30000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    3 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(50000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    5 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(100000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    10 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(500000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    50 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(1000000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    100 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(5000000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    500 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(10000000)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    1000 만원
                  </div>
                  <div disabled="false"
                       @click="changeAmount(user.members_cash)"
                       class="cursor-pointer h-44px rd-3px text-14px font-700 flex-center bg-bg-asInverse-10 border-1 border-solid border-primary c-primary hover:bg-primary hover:c-text-asInverse-05 transition-all duration-500 focus:none">
                    전체 금액
                  </div>
                </div>
                <div class="w-full">
                  <div class="flex flex-col gap-8px"><label
                      class="font-700 text-14px c-text-asInverse-02"
                      for="withdrawal-password">출금 비밀번호</label>
                    <div class="rd-3px h-44px input-outer-base"><input
                        id="withdrawal-password"
                        v-model="exchangeForm.withdrawalPw"
                        type="password"
                        pattern="[0-9]*"
                        placeholder="출금 비밀번호를 입력 하세요"
                        inputmode="numeric"
                        required=""
                        class="input-base px-10px! py-0px! h-100%!">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-0 flex-center bg-bg-asInverse-10 w-492px pb-34px sticky">
              <button class="rd-3px h-44px btn-base w-full mt-40px btn-primary" @click="processWithdrawal">확인</button>
            </div>
          </section>
          <section v-else class="bg-bg-asInverse-10 px-32px w-560px h-78vh">
            <div class="c-text-asInverse-02 pb-32px">
              <div class="h-1px bg-inverse-01 my-16px"></div>
              <div class="">
                <div
                    class="overflow-y-scroll scrollbar scrollbar-w-4px scrollbar-track-color-transparent scrollbar-thumb-radius-4px scrollbar-rounded">
                  <div>
                    <div class="listZone history_content noData">
                      <table>
                        <tbody>
                        <tr>
                          <th
                              class="w-20% h-39px b-1px b-solid font-size-13px table-cell v-middle text-center p-2px b-inverse-01 c-text-asInverse-06! bg-bg-asInverse-19!">
                            신청금액
                          </th>
                          <th
                              class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            요청시간
                          </th>
                          <th
                              class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            완료시간
                          </th>
                          <th
                              class="w-20% h-39px b-1px b-solid b-inverse-01 font-size-13px table-cell v-middle text-center p-2px c-text-asInverse-06! bg-bg-asInverse-19!">
                            처리상태
                          </th>
                        </tr>
                        <tr v-for="(row, index) in lists" :key="'withdrawalList'+index">
                          <td>{{ row.cash_amount | makeComma }}</td>
                          <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                          <td>{{ row.cash_done_datetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                          <td>
                            {{ row.cash_status }}
                          </td>
                        </tr>
                      </tbody>
                      </table>
                      <div class="b-1px b-solid b-inverse-01">
                        <div class="" style="">
                          <div class="noDataFrame">
                            <div class="pic"><img src="@/assets/images/noData.png" alt="no-data"></div>
                            <h2>데이터 없음</h2>
                            <h3>Sorry, Check no data</h3></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div><!--v-if--></div>
    </div>
  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
// import Loading from '../components/Loading'
// import MPagination from '@/components/MPagination'

import MSG from '@/contants/msg';
import {mapState} from "vuex";

export default {
  name: "Withdrawal",
  components: {
    // MPagination
    // Loading
    // SideBox
  },
  data: function () {
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      withdrawalPw: null,
      exchangeForm: {
        amount: 0,
        withdrawalPw: null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '출금',
        detailType: '캐시'
      },
      pagination: {},
      mType: 'withdrawal'
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.modals.withdrawal,
      user: state => state.user.user,
      lists: state => state.user.withdrawalHistory
    }),
  },
  watch: {
    exchangeForm: {
      deep: true,
      handler(val) {
        if (val.amount > this.user.members_cash) {
          alert('보유금을 초과할 수 없습니다.')
          this.exchangeForm.amount = this.user.members_cash;
          this.amountRound();
        }
      }
    },
    isOpen() {
      this.reqData()
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    reqData: function () {
      this.$store.dispatch('WITHDRAWAL_HISTORY', {payload: this.payload})
          .catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    // 환전 금액 변경
    changeAmount: function (amount) {
      this.exchangeForm.amount += amount;
    },
    resetAmount: function () {
      this.exchangeForm.amount = 0;
    },
    // 환전신청
    processWithdrawal: function () {
      if (this.exchangeForm.amount < 10000) return alert(MSG.error.minProcessAmount);
      if (!this.exchangeForm.withdrawalPw || this.exchangeForm.withdrawalPw === '') return alert(MSG.error.emptyPassword);
      this.processing = true;
      this.$store.dispatch('PROCESS_WITHDRAW', {payload: this.exchangeForm})
          .then(() => {
            this.processing = false;
            this.$store.dispatch('ME')
            alert(MSG.success.withdrawal);
          })
          .catch((err) => {
            this.processing = false;
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    amountRound: function () {
      let result = Math.floor(this.exchangeForm.amount / 10000)
      this.exchangeForm.amount = result * 10000
    },
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    toggleReq: function () {
      this.showReq = !this.showReq;
    },
    toggleList: function (type) {
      this.mType = type;
    },
    makeComma: function (str) {
      if (!str) return 0;
      return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  }
}
</script>


<style scoped>
.vfm--inset {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.vfm--absolute {
  position: absolute;
}

.vfm--fixed {
  position: fixed;
}
</style>
