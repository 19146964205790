export default {
  authorization: "접근 권한이 필요합니다.",
  isNotLogin: "로그인 후 이용해 주세요.",
  maxAmountCash: '보유 금액을 초과할 수 없습니다.',
  emptyPassword: '비밀번호를 입력해 주세요.',
  minProcessAmount: '최소 10,000원 이상 신청해 주세요.',
  minPointAmount: '5,000P 이상 전환 가능합니다.',
  hasPointErrMsg: '보유 포인트만큼 전환 가능합니다.',
  notAvailableBet: '베팅이 불가능한 항목이 있습니다.',
  accReqMsg: '계좌 문의를 하시겠습니까?',
  betSuccess: '베팅이 성공적으로 완료되었습니다.',
  betError: '베팅 중 오류가 발생했습니다.',
  amountOver: '베팅 금액이 보유 금액을 초과할 수 없습니다.',
  maxBetAmountOver: '베팅 금액이 최대 베팅 금액을 초과할 수 없습니다.',
  maxOddsOver: '최대 가능 배당을 초과했습니다.',
  error: {
    authorization: "접근 권한이 필요합니다.",
    isNotLogin: "로그인 후 이용해 주세요.",
    notAvailableBet: '베팅이 불가능한 항목이 있습니다.',

    emptyId: '아이디를 입력해 주세요.',
    emptyPassword: '비밀번호를 입력해 주세요.',
    emptyCode: '코드를 입력해 주세요.',
    emptyContact: '연락처를 입력해 주세요.',
    emptyBank: '은행명을 선택해 주세요.',
    emptyDepositor: '계좌주를 입력해 주세요.',
    emptyBankNum: '계좌번호를 입력해 주세요.',
    emptyWithdrawalPw: '출금 비밀번호를 입력해 주세요.',
    emptyNick: '닉네임을 입력해 주세요.',
    shortPassword: '비밀번호는 8자리 이상 입력해 주세요.',
    difPw: '입력된 비밀번호가 일치하지 않습니다.',
    minProcessAmount: '최소 10,000원 이상 신청해 주세요.',
    socketNotConnected: '인플레이 연결이 올바르지 않습니다. 새로고침 후 이용해 주세요.'
  },
  success: {
    signup: '회원가입이 성공적으로 완료되었습니다.',
    welcome: '방문을 환영합니다!',
    deposit: '충전 신청이 완료되었습니다.',
    withdrawal: '환전 신청이 완료되었습니다.',
    logOut: '성공적으로 로그아웃되었습니다.'
  },
};