<template>
  <div v-if="popup_bg">
    <!-- <template v-for="(popup, index) in event_popup">
      <div class="popup_blank" :key="'modalPopup'+index" v-if="isVisible(popup)">
        <div class="new_message_popup2" v-if="isVisible(popup)">
          <div class="bg_mask_pop_title">
            <span class="popup_logo">
              <img style="width: 100px;" src="@/assets/img/main/luna_logo_main.png">
            </span>
            <span class="popup_close closeLogoutBtn" @click="closePopup(popup)">
              <img src="@/assets/img/main/popup_close_one.png">
            </span>
          </div>
          <div class="popup_content" style="padding: 20px">
            <div v-html="popup.url">
            </div>
          </div>
          <div class="bg_mask_pop_title" style="float: right;">
            <span style="cursor: pointer; color:gray;" @click="setInVisible(popup)">24시간동안 보이지 않기</span>
          </div>
        </div>
      </div>
    </template> -->
    <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none NAV_ad" style="z-index: 1002;">
      <div class="vfm__content">
          <div class="contentFrame">
              <div class="" v-for="(popup, index) in event_popup" v-if="popup.isVisible" :key="'modalPopup'+index">
                  <div class="pupop_content pupop_registered modalOpen"
                      style="top: 50px; left: 650px;">
                      <h5 class="XX">
                          <span class="icon-iconCross" @click="closePopup(popup)"></span>
                      </h5>
                      <div class="reg">
                          <div class="top"><img src="../assets/img/main/main_toplogo.png" alt=""></div>
                          <div class="content" v-html="popup.url"></div>
                          <div class="btnZone">
                              <button class="btnP greyBtn" @click="closePopup(popup)">오늘 하루 열지 않기</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { pop } from 'core-js/core/array';

export default {
  name: "EventPopup",
  computed: {
    event_popup: function(){
      return this.$store.state.event.event_popup;
    },
    popup_bg() {
       console.log('Checking popup_bg visibility');
       return this.event_popup.find((pop) => pop.isVisible);
     },
  },
  methods: {
    closePopup(popup){
      popup.isVisible = false;
      this.$store.dispatch('CLOSE_MODAL', { seq: popup.seq });
    },
    setInVisible(popup){
      const date = new Date();
      date.setDate(date.getDate() + 1);
      popup.isSetDate = date;
      popup.isVisible = false;
      this.closePopup(popup);
      localStorage.setItem('popups',JSON.stringify(this.$store.state.event.event_popup));
      this.$forceUpdate();
    },
    isVisible(popup) {
      const storagePopup = localStorage.getItem('popups');
      const popups = JSON.parse(storagePopup) || [];
      const p = popups.find(e => e.seq === popup.seq);
      if (!p) return true;
      if (!p.isSetDate && p.isVisible) return true;
      if (p.isSetDate && new Date(p.isSetDate) < new Date()) return true;
      return false;
    },
    savePopupState() {
      localStorage.setItem('popups', JSON.stringify(this.$store.state.event.event_popup));
    }
  }
}
</script>

<style scoped>
.vfm--inset {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.vfm--fixed {
    position: fixed;
}
.vfm--absolute {
    position: absolute;
}
.NAV_ad {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: var(--bgColor039);
    z-index: 999;
}
.NAV_ad .contentFrame {
    position: relative;
}
.NAV_ad .pupop_content {
    background-color: var(--bgColor017);
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}

.NAV_ad .pupop_content .XX {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    -webkit-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out
}

.NAV_ad .pupop_content .XX:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
}

.NAV_ad .pupop_content .XX span {
    font-size: 1vw;
    color: var(--txtColor105);
    font-weight: 700
}

.NAV_ad .pupop_content .reg {
    width: 100%;
    margin: 0 auto
}

.NAV_ad .pupop_content .reg .up {
    margin-bottom: 10px
}

.NAV_ad .pupop_content .reg .top {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: var(--bgColor_04)
}

.NAV_ad .pupop_content .reg .top img {
    height: 2vw
}

.NAV_ad .pupop_content .reg .content {
    width: 100%;
    height: auto
}

.NAV_ad .pupop_content .reg .content img {
    width: 100%
}

.NAV_ad .pupop_content .reg .btnZone {
    width: 100%;
    height: 72px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--bgColor_01);
    margin-top: -2px
}

.NAV_ad .pupop_content .reg .btnZone .btnP {
    border: 0px;
    font-size: .8vw;
    padding: .7vw 3vw;
    border-radius: 20px
}

.modalOpen {
    animation: modalOpen .2s ease-in-out forwards
}

@keyframes modalOpen {
    0% {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.modalClose {
    animation: modalClose .2s ease-in-out forwards
}

@keyframes modalClose {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(0)
    }
}

</style>
