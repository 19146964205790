<template>

  <section  v-if="!this.$store.state.page.isMobile">
    <h2 class="subTitle">카지노 환전 CASINO RECHARGE MONEY</h2>


    <div class="nbox">

      <div class="charge_text">
        <h3 class="tit-bet" style="border-top: 1px solid #a97bf5;">카지노 환전신청</h3>
          <div class="charge_box">
            <div style="padding: 20px;">
              <table>
                <tbody>
                <tr>
                  <th>보유머니</th>
                  <td>
                    <p>{{ user.members_cash | makeComma }}원 / <span>보유 크레딧 : {{ casinoBalance | makeComma }}</span></p>
                  </td>
                </tr>
                <tr>
                  <th>충전금액</th>
                  <td>
                    <div>
                      <input type="number" v-model="amount" @focusout="amountRound">원
                    </div>
                    <ul>
                      <li @click="addAmount(1000)">1,000원</li>
                      <li @click="addAmount(5000)">5,000원</li>
                      <li @click="addAmount(10000)">1만원</li>
                      <li @click="addAmount(50000)">5만원</li>
                      <li @click="addAmount(100000)">10만원</li>
                      <li @click="addAmount(200000)">20만원</li>
                      <li @click="addAmount(500000)">50만원</li>
                      <li @click="addAmount(1000000)">100만원</li>
                      <li @click="addAmount(2000000)">200만원</li>
                      <li @click="addAmount(5000000)">500만원</li>
                      <li @click="allAmount">전체금액</li>

                      <li @click="resetAmount">정정</li>
                    </ul>
                    <p>보유 크레딧을 확인 후 보유 한도내에서 환전할 금액을 입력해주세요.</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <button class="charge_btn"  v-if="!isLoading" @click="reqWithdrawal">환전하기</button>
            <div style="text-align: center; background: #1c2021;" v-else>
              <Loading></Loading>
            </div>
          </div>

          <h3 class="tit-bet">출금내역</h3>
          <table class="listTable02" style="width: 100%">
            <tbody>
            <tr>
              <th>번호</th>
              <th>구분</th>
              <th>신청금액</th>
              <th>신청일자</th>
              <th>상태</th>
            </tr>

            <tr v-if="lists.length === 0">
              <td colspan="5">검색된 내역이 없습니다.</td>
            </tr>
            <tr v-else v-for="(row, index) in lists" :key="'depositList'+index">
              <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
              <td>카지노</td>
              <td>{{ row.cash_amount | makeComma }}</td>
              <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
              <td>{{ row.cash_status }}</td>
            </tr>
            </tbody>
          </table>
<!--          <div class="pagingBox black">-->
<!--            <div class="paging">-->
<!--              <a href="javascript:void(0)" class="btnFirst"><img src="@/assets/img/ico/ico_first_black.png"></a>-->
<!--              <a href="javascript:void(0)" class="btnPrev"><img src="@/assets/img/ico/ico_prev_black.png"></a>-->

<!--              <a href="javascript:void(0)" class="on">1</a>-->
<!--              <a href="javascript:void(0)">2</a>-->
<!--              <a href="javascript:void(0)">3</a>-->
<!--              <a href="javascript:void(0)">4</a>-->
<!--              <a href="javascript:void(0)">5</a>-->
<!--              <a href="javascript:void(0)">6</a>-->
<!--              <a href="javascript:void(0)">7</a>-->
<!--              <a href="javascript:void(0)">8</a>-->
<!--              <a href="javascript:void(0)">9</a>-->
<!--              <a href="javascript:void(0)">10</a>-->

<!--              <a href="javascript:void(0)" class="btnNext"><img src="@/assets/img/ico/ico_next_black.png"></a>-->
<!--              <a href="javascript:void(0)" class="btnLast"><img src="@/assets/img/ico/ico_last_black.png"></a>-->
<!--            </div>-->
<!--          </div>-->
      </div>
    </div>

  </section>
  <div class="mini-game" v-else>
    <h2 class="subTitle2" style="font-size: 18px; margin-bottom:0">카지노 환전 CASINO CHARGE MONEY</h2>
    <div class="charge_text">
      <div class="charge_box">
        <div style="padding: 10px;">
          <table>
            <tbody>
            <tr>
              <th>보유머니</th>
              <td>
                <p>{{ user.members_cash | makeComma }}원 / <span>보유 크레딧 : {{ casinoBalance | makeComma }}</span></p>
              </td>
            </tr>
            <tr>
              <th>환전 금액</th>
              <td>
                <div>
                  <input type="number" v-model="amount" @focusout="amountRound">원
                </div>
                <ul>
                  <li @click="addAmount(1000)">1,000원</li>
                  <li @click="addAmount(5000)">5,000원</li>
                  <li @click="addAmount(10000)">1만원</li>
                  <li @click="addAmount(50000)">5만원</li>
                  <li @click="addAmount(100000)">10만원</li>
                  <li @click="addAmount(200000)">20만원</li>
                  <li @click="addAmount(500000)">50만원</li>
                  <li @click="addAmount(1000000)">100만원</li>
                  <li @click="addAmount(2000000)">200만원</li>
                  <li @click="addAmount(5000000)">500만원</li>
                  <li @click="allAmount">전체금액</li>

                  <li @click="resetAmount">정정</li>
                </ul>
                <p>보유 크레딧을 확인 후 보유 한도내에서 환전할 금액을 입력해주세요.</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <button class="charge_btn" v-if="!isLoading" @click="reqWithdrawal">환전하기</button>
        <div style="text-align: center; background: #1c2021;" v-else>
          <Loading></Loading>
        </div>
      </div>

      <h3 class="tit-bet">출금내역</h3>
      <table class="listTable02" style="width: 100%">
        <tbody>
          <tr>
            <th>번호</th>
            <th>구분</th>
            <th>신청금액</th>
            <th>신청일자</th>
            <th>상태</th>
          </tr>

          <tr v-if="lists.length === 0">
            <td colspan="5">검색된 내역이 없습니다.</td>
          </tr>
          <tr v-else v-for="(row, index) in lists" :key="'depositList'+index">
            <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
            <td>출금</td>
            <td>{{ row.cash_amount | makeComma }}</td>
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>{{ row.cash_status  }}</td>
          </tr>
        </tbody>
      </table>
<!--      <div class="pagingBox black">-->
<!--        <div class="paging">-->
<!--          <a href="javascript:void(0)" class="btnFirst mobile"><img src="@/assets/img/ico/ico_first_black.png"></a>-->
<!--          <a href="javascript:void(0)" class="btnPrev mobile"><img src="@/assets/img/ico/ico_prev_black.png"></a>-->

<!--          <a href="javascript:void(0)" class="mobile">1</a>-->
<!--          <a href="javascript:void(0)" class="mobile">2</a>-->
<!--          <a href="javascript:void(0)" class="mobile">3</a>-->
<!--          <a href="javascript:void(0)" class="mobile">4</a>-->
<!--          <a href="javascript:void(0)" class="mobile">5</a>-->
<!--          <a href="javascript:void(0)" class="mobile">6</a>-->
<!--          <a href="javascript:void(0)" class="mobile">7</a>-->
<!--          <a href="javascript:void(0)" class="mobile">8</a>-->
<!--          <a href="javascript:void(0)" class="mobile">9</a>-->
<!--          <a href="javascript:void(0)" class="mobile">10</a>-->

<!--          <a href="javascript:void(0)" class="btnNext mobile"><img src="@/assets/img/ico/ico_next_black.png"></a>-->
<!--          <a href="javascript:void(0)" class="btnLast mobile"><img src="@/assets/img/ico/ico_last_black.png"></a>-->
<!--        </div>-->
<!--      </div>-->
    </div>


  </div>
</template>

<script>
import Loading from '@/components/Loading'
import {mapState} from "vuex";

export default {
  name: "Main",
  components: {
    Loading
  },
  data: function(){
    return {
      amount: 0,
      casinoBalance: 0,
      isLoading: false,
      thirdParty: 0,
      payload: {
        page: 1,
        size: 10,
        type: '입금',
        detailType: '카지노',
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
  },
  mounted() {
    this.checkBalance();
    this.reqData();
  },
  watch: {
    amount: function(amt){
      if (amt > this.casinoBalance){
        this.amount = this.casinoBalance;
        this.amountRound()
      }
    }
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('DEPOSIT_HISTORY', { payload: this.payload })
          .catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    checkBalance: function(){
      this.$store.dispatch('CHECK_CASINO_WALLET')
          .then(data => {
            if (Array.isArray(data)){
              const result = data[0].data;
              if (result.success){
                this.casinoBalance = result.payload.balance;
                this.thirdParty = result.payload.details.find(e => e.thirdPartyBalance > 0).thirdParty;
              }
            } else {
              const result = data.data;
              if (result.success){
                this.casinoBalance = result.payload.balance;
                this.thirdParty = result.payload.details.find(e => e.thirdPartyBalance > 0).thirdParty;
              }
            }
          }).catch(err => {
        alert(err.response.data.error || '관리자에게 문의하세요!');
      })
    },
    addAmount: function(amt){
      return this.amount += amt;
    },
    resetAmount: function(){
      return this.amount = 0;
    },
    reqWithdrawal: function(){
      if (this.amount < 10000) return alert('최소 10,000원 이상 신청하셔야합니다.');
      if (this.amount > this.casinoBalance) return alert('보유크레딧 이상 신청하셨습니다.');

      this.isLoading = true;
      const data = {
        payload: {
          thirdParty: this.thirdParty,
          amount: this.amount
        }
      };
      this.$store.dispatch('PROCESS_CASINO_WITHDRAW', data)
          .then(data => {
            const result = data.data;
            if (result.success){
              this.reqData();
              this.isLoading = false;
              this.$store.dispatch('ME')
              alert('신청 되었습니다.');
            }
          }).catch(err => {
        this.isLoading = false;
        alert(err.response.data.error || '관리자에게 문의하세요!');
      })
    },
    amountRound: function(){
      let result = Math.floor(this.amount / 10000)
      this.amount = result * 10000
    },
    allAmount: function(){
      this.amount = this.user.members_cash;
      this.amountRound();
    }
  },

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
