<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
                <div class="titlePic">
                    <div class="titlePwrapper">
                        <div class="leftZone"><span class="Logo"></span>
                            <span>고객센터</span></div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="main_content">
                    <div class="main_content">
                        <div class="main_content_wrap main_content_wrap_notice deep_wrap_notice">
                            <div class="noticeFrame serviceFrame messageFrame">
                                <div class="contentZone">
                                    <div class="contentZone">
                                        <div class="time timeReply">
                                          <div class="pageBack">
                                            <router-link :to="{name:'고객센터-목록'}">
                                              <span class="icon-icconArrow02"></span> 뒤로
                                            </router-link>
                                              <div class="crossLine"></div>
                                          </div>
                                          <div class="title">{{ article.board_title }}</div>
                                          <span class="timeR"><div class="name">{{ user.members_id }}</div>
                                            <span>{{ article.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
                                          </span>
                                        </div>
                                        <div class="letterZone">
                                          <div class="time" v-if="article.replyDate">
                                            <div class="name">내용</div>
                                          </div>
                                          <p class="content-body" v-html="renderHtml(article.board_content)"></p>
                                        </div>
                                        <div class="letterZone" v-for="comment in article.comments" :key="'comment'+ comment.seq">
                                          <div class="time" v-if="article.replyDate">
                                            <div class="name">답변시간</div>
                                            <span>{{ article.replyDate | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
                                          </div>
                                          <p class="content-body" v-html="renderHtml(comment.content)"></p>
                                        </div>
                                        <div class="btnFrame btnReply">
                                            <button class="btnP1 blueLLineBtn01" @click="$router.push({name: '고객센터-목록'})">목록으로</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="" id="content" v-else>
    <div class="wrapPage v_deep_service_center">
        <ul class="tab-list">
            <!-- <li><rotuer-link :to="{name:'베팅내역'}">베팅내역</rotuer-link></li> -->
            <li class="active"><a>고객센터</a></li>
            <!-- <li><rotuer-link :to="{name:'쪽지-목록'}">쪽지</rotuer-link></li> -->
        </ul>
        <div class="wrapper">
            <div class="title-p">
                <div>고객센터</div>
                <span>Customer</span></div>
            <div class="content-table">
                <div>
                    <div class="letterZone">
                        <div class="content-time customer-time">
                          <span>{{ article.board_title }}</span>
                          <div class="other-info"><span class="nick-name">{{ user.members_id }}</span>
                          <span class="time">{{ article.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss')}}</span></div>
                        </div>
                        <div class="content-info">
                          <p class="content-body" v-html="renderHtml(article.board_content)"></p>
                        </div>
                    </div>
                    <div class="letterZone" v-for="comment in article.comments" :key="'comment'+ comment.seq">
                        <div class="content-time reply-time"><!---->
                            <button class="btnColor10">답변완료</button>
                            <div class="other-info"><!---->
                              <span class="nick-name">관리자</span>
                              <span class="time">{{ comment.createdAt | formatDate('YYYY/MM/DD HH:mm:ss')}}</span></div>
                        </div>
                        <div class="content-info reply-info">
                          <p class="content-body" v-html="renderHtml(comment.content)"></p>
                        </div>
                    </div>
                </div>
                <div class="btn-center">
                    <button class="return-button" @click="$router.push({name:'고객센터-목록'})">목록으로</button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Detail",
  props: ['isMobile'],
  data: function(){
    return {
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.depositHistory
    }),
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('GET_BOARD_DETAIL', { seq: this.$route.params.seq })
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
            console.log(this.article);
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
  }
}
</script>

<style scoped>
@media (min-width: 500px) {
  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .tAreaFrame {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 10px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .txt {
    font-size: 16px;
    width: 12%;
    color: var(--txtColor_03);
    background-color: var(--bgColor_036);
    border: 1px solid var(--bdColor_015);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .areaFrame {
    background-color: var(--main)!important;
    border: 1px solid var(--bdColor_015);
    width: 95%;
    padding: 10px 0 10px 20px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .areaFrame textarea {
    height: 312px;
    background-color: var(--main);
    width: 100%;
    padding: 20px;
    font-size: 14px;
    color: var(--txtColor36);
    border: 1px solid var(--bdColor_015);
    line-height: 1.6
}

 .deep_wrap_notice .serviceFrame .serviceArea .sendMSG {
    background-color: var(--bdColor039);
    border: 1px solid var(--bdColor047);
    color: var(--txtColor66);
    font-size: 14px;
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    left: 50%;
    bottom: -11px;
    transform: translate(-50%);
    position: absolute;
    opacity: 0
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea textarea:focus, .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea textarea:focus-visible {
    border: 1px solid var(--bdColor_015);
    outline: none
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame {
    display: flex;
    width: 100%;
    text-align: center;
    height: 42px;
    margin-bottom: 17px;
    align-items: center;
    position: relative
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .txtT {
    color: var(--txtColor66);
    font-size: 14px;
    font-weight: 700;
    width: 5%;
    text-align: left
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content {
    position: relative;
    height: 42px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content input {
    width: 100%;
    height: 42px;
    padding-left: 14px;
    background-repeat: no-repeat;
    margin-bottom: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--txtColor54);
    background-color: var(--bgColor017);
    border: 1px solid var(--bdColor045)!important;
    font-size: 16px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content input::placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .btnP {
    padding: 14px 35px;
    border-radius: 20px;
    position: absolute;
    right: 0
}

 .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .input_txt {
    color: var(--txtColor66);
    font-size: 20px;
    font-weight: 700
}

 .deep_wrap_notice .serviceFrame .serviceOuter .btnZone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0
}

 .deep_wrap_notice .serviceFrame .serviceOuter .btnZone .btnP {
    height: 40px;
    padding: 0 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    border: 0px;
    transition: .2s ease-out;
    background-color: var(--bgColor_02)
}

 .deep_wrap_notice .serviceFrame .serviceOuter .btnZone .btnP:hover {
    background-color: var(--btnColor_001)!important;
    color: var(--txtColor01_h)!important
}

 .deep_wrap_notice .serviceFrame .main_content_wrap_notice .listZone table tr td a.goService {
    font-size: 12px;
    color: var(--txtColor02);
    display: block;
    width: 60px;
    height: 25px;
    line-height: 25px;
    border-radius: 3px;
    margin: 0 auto;
    font-weight: 700;
    transition: .4s ease-out
}

 .deep_wrap_notice .serviceFrame a.done {
    background-color: var(--btnColor016)
}

 .deep_wrap_notice .serviceFrame a.done:hover {
    background-color: var(--btnColor016_h)
}

 .deep_wrap_notice .serviceFrame a.undone {
    background-color: var(--btnColor014)
}

 .deep_wrap_notice .serviceFrame a.undone:hover {
    background-color: var(--btnColor014_h)
}

 .deep_wrap_notice .serviceFrame a.write {
    background-color: var(--btnColor017)
}

 .deep_wrap_notice .serviceFrame a.write:hover {
    background-color: var(--btnColor017_h)
}

 .deep_wrap_notice .serviceFrame .letterZone, .deep_wrap_notice .serviceFrame .letterBack {
    padding: 26px 32px;
    border: 1px solid var(--bdColor070);
    background-color: var(--bgColor_024)
}

 .deep_wrap_notice .serviceFrame .letterZone .time {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: var(--txtColor95);
    font-size: 14px;
    font-weight: 100;
    position: relative;
    padding-bottom: 7px
}

 .deep_wrap_notice .serviceFrame .letterZone .time .name {
    margin-right: 26px;
    position: relative
}

 .deep_wrap_notice .serviceFrame .letterZone .time .name:after {
    content: "";
    width: 1px;
    height: 13px;
    background-color: var(--txtColor116);
    position: absolute;
    right: -12px
}

 .deep_wrap_notice .serviceFrame .letterZone .time .btnP1 {
    padding: 9px 14px;
    border-radius: 20px;
    position: absolute;
    left: 0
}

 .deep_wrap_notice .serviceFrame .letterZone p {
    font-size: 14px;
    color: var(--txtColor95);
    margin-top: 12px;
    line-height: 2;
    text-align: justify
}

 .deep_wrap_notice .serviceFrame .btnFrame {
    margin-top: 30px
}

 .deep_wrap_notice .serviceFrame .btnFrame .btnP1 {
    height: 40px;
    padding: 0 47px;
    color: var(--txtColor02);
    background-color: var(--bgColor_02);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    transition: .2s ease-out
}

 .deep_wrap_notice .serviceFrame .btnFrame .btnP1:hover {
    background-color: var(--btnColor_001)!important;
    color: var(--txtColor01_h)!important
}

 .deep_wrap_notice .serviceFrame .depositP {
    line-height: 3.5!important
}

 .deep_wrap_notice .serviceOuterReply {
    display: none;
    transition: .4s ease-out
}

 .deep_wrap_notice .btnReply01 {
    display: none!important
}

 .deep_wrap_notice .btnReply01 .btnP1, .deep_wrap_notice .btnReply02 .btnP1 {
    font-size: 14px;
    display: flex;
    border-radius: 20px;
    font-weight: 700;
    transition: .4s ease-out;
    cursor: pointer;
    align-items: center;
    width: 240px!important
}

 .deep_wrap_notice .serviceReplyFrame .serviceOuterReply {
    display: block!important
}

 .deep_wrap_notice .serviceReplyFrame .timeReply, .deep_wrap_notice .serviceReplyFrame .btnReply {
    display: none!important
}

 .deep_wrap_notice .serviceReplyFrame .btnReply01 {
    display: flex!important;
    justify-content: center;
    align-items: center
}

 .deep_wrap_notice .contentZone .time .title, .deep_wrap_notice .contentZone .time span.timeR {
    color: var(--txtColor95)!important
}

button:focus {
    outline: none;
    background: none
}
 .main_content_wrap_notice {
    width: 1440px!important
}

 .noticeFrame {
    width: 100%;
    height: auto;
    padding-bottom: 22px
}

 .noticeFrame .title {
    color: var(--txtColor22);
    font-size: 26px;
    font-weight: 600
}

 .noticeFrame .title span {
    color: var(--txtColor23);
    font-size: 14px;
    margin-left: 7px
}

 .main_content_wrap_notice .listZone {
    width: 100%;
    margin-top: 0
}

 .main_content_wrap_notice .listZone table {
    width: 100%
}

 .main_content_wrap_notice .listZone table tr {
    background-color: var(--main);
    transition: .4s ease-out;
    border: 1px solid var(--bdColor070)
}

 .main_content_wrap_notice .listZone table tr:nth-child(odd) {
    background-color: var(--bgColor150)!important
}

 .main_content_wrap_notice .listZoneA table tr:nth-child(odd) {
    background-color: var(--bgColor_02)!important
}

 .main_content_wrap_notice .listZone table tr:first-child, .main_content_wrap_notice .listZone table tr:first-child:hover {
    background-color: var(--bgColor_02)!important;
    color: var(--txtColor_03)
}

 .main_content_wrap_notice .listZone table tr th {
    padding: 10px 0;
    font-size: 14px;
    border-bottom: 1px solid var(--bdColor047);
    position: relative
}

 .main_content_wrap_notice .listZone table tr th:first-child:before {
    content: "";
    width: 1px;
    height: 21px;
    background-color: var(--bdColor070);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none
}

 .main_content_wrap_notice .listZone table tr th:after {
    content: "";
    width: 1px;
    height: 21px;
    background-color: var(--bdColor070);
    position: absolute;
    right: 0;
    bottom: 0
}

 .main_content_wrap_notice .listZone table tr th.number {
    width: 9%
}

 .main_content_wrap_notice .listZone table tr th.name {
    width: 16%
}

 .main_content_wrap_notice .listZone table tr th.date {
    width: 14%
}

 .main_content_wrap_notice .listZone table tr th:last-child:after {
    display: none
}

 .main_content_wrap_notice .listZone table tr td {
    color: var(--txtColor_017);
    font-size: 13px;
    padding: 21px 19px;
    transition: all .3s ease-out;
    position: relative;
    font-family: var(--font-family)!important;
    font-weight: 400!important;
    line-height: 1.6
}

 .main_content_wrap_notice .messageZone table tr td {
    padding: 0
}

 .main_content_wrap_notice .listZone table tr td .btnM1 {
    padding: 13px 0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: .4s ease-out
}

 .main_content_wrap_notice .listZone table tr td.fc {
    display: flex;
    align-items: center;
    justify-content: center
}

 .main_content_wrap_notice .listZone table tr td.back {
    color: var(--txtColor_05)!important
}

 .main_content_wrap_notice .listZone table tr td span.textOver {
    color: var(--txtColor_017);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 143px;
    display: block;
    font-family: var(--font-family)!important;
    font-weight: 400!important
}

 .main_content_wrap_notice .listZone table tr td a {
    color: var(--txtColor_017);
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    font-family: var(--font-family)!important;
    font-weight: 400!important
}

 .main_content_wrap_notice .messageZone table tr td a {
    padding: 21px 19px
}

 .main_content_wrap_notice .listZone table tr td a.goResult {
    color: var(--txtColor02);
    font-size: 14px;
    display: block;
    background-color: var(--btnColor038);
    padding: 12px 0;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s all ease-out
}

 .main_content_wrap_notice .listZone table tr td a.goResult:hover {
    background-color: var(--btnColor038_h)
}

 .main_content_wrap_notice .listZone table tr td a.goNotice {
    font-size: 12px;
    color: var(--txtColor013);
    background-color: var(--btnColor09);
    display: block;
    width: 50px;
    height: 25px;
    line-height: 25px;
    border-radius: 3px;
    margin: 0 auto;
    font-weight: 700;
    transition: .4s ease-out
}

 .main_content_wrap_notice .listZone table tr td a.goNotice:hover {
    background-color: var(--btnColor09_h)
}

 .main_content_wrap_notice .listZone table tr td span {
    color: var(--txtColor_017)
}

 .main_content_wrap_notice .listZone table tr td h5 {
    display: none
}

 .main_content_wrap_notice .listZone table tr td img.logo {
    width: 77px;
    margin: 0 auto
}

 .main_content_wrap_notice .listZone table tr td span.icon-icconLOAD {
    color: var(--txtColor66);
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
    margin-right: 5px
}

 .main_content_wrap_notice .listZone table tr td .btnFrame {
    display: flex;
    align-items: center
}

 .main_content_wrap_notice .listZone table tr td .btnT {
    color: var(--txtColor02);
    background-color: var(--bgColor131);
    width: 121px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s ease-out;
    margin-left: 6px;
    border: 0px
}

 .main_content_wrap_notice .listZone table tr td .btnT:hover {
    background-color: var(--btnColor034_h)
}

 .main_content_wrap_notice .listZone table tr:nth-child(2n) {
    background-color: var(--main)!important
}

 .main_content_wrap_notice .listZone table tr:nth-child(2n):hover .uLine, .messageFrame .listZone tr.mailUse:hover .uLine {
    text-decoration: underline
}

 .main_content_wrap_notice .listZone table tr:nth-child(2n):hover .frame01, .messageFrame .listZone tr.mailUse:hover .frame01 {
    border: 1px solid var(--bdColor058)
}
}

@media (max-width: 500px) {
  section#content {
      overflow-x: hidden
  }

  .needTop .swiper {
      margin-top: 13vw!important
  }

  .needTop section:first-child {
      margin-top: 13vw!important
  }

  .needTop iframe:first-child {
      margin-top: 13vw!important
  }

  .needTop>.wrapPage {
      top: 13vw!important
  }

  .needTop section:nth-child(2) {
      top: 85vw!important
  }


  ul.tab-list {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

ul.tab-list li {
    width: 100vw;
    border-right: 1px solid var(--borderColor22)
}

ul.tab-list li:last-child {
    border: none
}

ul.tab-list li a {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: .82rem;
    background: var(--btnColor03);
    color: var(--txtColor01_op);
    padding: 2.5vw 0;
    font-weight: 600
}

ul.tab-list li.active a {
    background: var(--bgColor10);
    color: var(--txtColor01)
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    ul.tab-list li a {
        font-size:1.2rem
    }

    .title-p div {
        font-size: 2rem
    }

    .title-p span {
        font-size: 1.2rem
    }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    ul.tab-list li a {
        font-size:1.8rem
    }

    .title-p div {
        font-size: 2.8rem
    }

    .title-p span {
        font-size: 1.6rem
    }
}

}
</style>
