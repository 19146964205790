<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="icon-iiconLogoB"></span><span>베팅규정</span></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice">
                    <div class="noticeFrame">
                      <div show-field-date="false">
                        <div class="listZone">
                          <table>
                            <tbody>
                            <tr>
                              <th class="ac number" width="10%"> No.</th>
                              <th class="ac" width="70%">제목</th>
                              <th class="ac name" width="20%">작성자</th></tr>
                            <tr v-for="(row, index) in lists" :key="'notice'+index" @click="movePage(row.board_seq)">
                              <td class="ac"><span>{{ index + 1}}</span></td>
                              <td class="al">
                                <a @click="movePage(row.board_seq)">
                                  {{ row.board_title }}
                                </a>
                              </td>
                              <td class="ac fc">관리자</td>
                            </tr>
                            </tbody>
                          </table>
                          <div style="" class="" v-if="lists === 0">
                            <div class="noDataFrame">
                              <div class="pic"><img src="/assets/noData-YtuvU2u_.png" alt="no-data"></div>
                              <h2>데이터 없음</h2>
                              <h3>Sorry, Check no data</h3></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section v-else id="content" class="">
    <div class="wrapPage">
      <div class="wrapper">
        <div class="title-p">
          <div>베팅규정</div>
          <span>Notification</span></div>
        <div class="content-table">
          <table>
            <thead>
            <tr>
              <th class="w20"> No.</th>
              <th class="w60">제목</th>
              <th class="w20">작성자</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in lists" :key="'notice'+index" @click="movePage(row.board_seq)">
              <td class="w20">
                <span>{{ index + 1 }}</span>
              </td>
              <td class="w60 left">{{ row.board_title }}</td>
              <td class="w20">
                <span>관리자</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="absolute! top-0"></div>
        <!--        <div class="pagination">-->
        <!--          <ul>-->
        <!--            <li class="first"><a href="javascript: void(0)">처음</a></li>-->
        <!--            <li class="prev"><a href="javascript: void(0)">이전</a></li>-->
        <!--            <li class="pages on"><a href="javascript: void(0)">1</a></li>-->
        <!--            <li class="next"><a href="javascript: void(0)">다음</a></li>-->
        <!--            <li class="last"><a href="javascript: void(0)">끝</a></li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
// import Loading from '../../../components/LoadingGray'

export default {
  name: "Rule",
  props: ['isMobile'],
  components: {
    // Loading,
  },
  data: function () {
    return {
      lists: null,
      openDetail: [0],
      isLoading: false,
      payload: {
        page: 1,
        size: 100,
        keyword: null,
        type: 'rule',
        sitename: 'newbet'
      },
    }
  },
  mounted() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function (payload) {
      this.isLoading = false;
      return this.$store.dispatch('GET_BOARD', {payload})
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    movePage: function (page) {
      console.log(page);
      this.$router.push({name: `규정-자세히`, params: {seq: page}})
    },
    checkArray: function (id) {
      return this.openDetail.indexOf(id) >= 0
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetail, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetail.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetail = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },

    // 모바일 적용
    renderHtml: function (str) {
      str.replace('<img', '<img @click="checkTest(1);" ')
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    checkTest: function (t) {
      console.log(t);
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {
  float: left;
  width: 100%;
  padding: 30px 0 50px 0;
}

.contents {
  width: 1330px;
  margin: 0px auto;
  min-height: 600px;
}

.sub_left {
  width: 260px;
  float: left;
  margin: 0 10px 0 0
}

.sub_right {
  width: 1060px;
  float: left;
}

.l_tabs {
  width: 100%;
  float: left;
}

.l_tabs li {
  width: 240px;
  padding: 0 0 0 20px;
  margin: 0 0 1px 0;
  height: 40px;
  background: #262a2b;
  line-height: 40px;
}

.l_tabs li a {
  width: 100%;
  display: inline-block;
}

.l_tabs li:hover {
  background: #2a2e2f
}

ul.l_tabs li.active a {
  color: #ffffff
}

ul.l_tabs li.active, ul.l_tabs li.active a:hover {
  background: #A97BF5;
}

.left_tab_con {
  width: 100%;
}

/* accordion */
.smk_accordion {
  width: 100%;
  position: relative;
}

.smk_accordion .acc_section .acc_head {
  position: relative;
  background: #2d3232;
  padding: 10px 0 10px 0;
  display: block;
  cursor: pointer;
  width: 100%;
  text-indent: 10px
}

.smk_accordion .acc_section .acc_head h3 {
  line-height: 1;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 700
}

.smk_accordion .acc_section .acc_content {
  background: #191a1b;
}

.smk_accordion .acc_section.acc_active > .acc_head {
  background: #222727;
}

.smk_accordion li {
  margin: 0 0 1px 0
}

.acc_content_in_1 {
  padding: 60px;
  background: #191a1b;
}

.acc_content_in_2 {
  padding: 40px;
  background: #191a1b;
}

.acc_btn_wrap {
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0
}
</style>
