<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="Logo"></span>
                    <span>쪽지</span>
                  </div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice deep_wrap_notice">
                    <div class="messageFrame noticeFrame">
                      <div class="editMessage">
                        <div class="frame"><span
                            class="icon-icconMailList01"></span>
                          <div class="hoverMsg" style="display: none;">전체 읽음</div>
                        </div>
<!--                        <div class="messagesNO">총 받은 메세지: <span-->
<!--                            class="no">97</span>-->
<!--                        </div>-->
                      </div>
                      <div class="listZone">
                        <table>
                          <tbody>
                          <tr>
                            <th class="ac siteMailTH">상태</th>
                            <th class="ac siteMailTH01 w-8%">작성자</th>
                            <th class="ac siteMailTH02">제목</th>
                            <th class="ac siteMailTH03">작성시간</th>
                          </tr>
                          <tr class="mailUse able" v-for="(row, index) in lists" :key="'notes'+index " @click="movePage(row.seq)">
                            <td class="al checkUse">
                              <div class="iconFrame">
                                <span class="icon-icconMail02a"></span>
                                <h4 v-if="row.readAt">읽음</h4>
                                <h4 v-else>읽기전</h4>
                              </div>
                            </td>
                            <td class="ac">관리자</td>
                            <td class="al"><a class="" href="javascript:void(0)">{{ row.title}}</a></td>
                            <td class="ac">{{ row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                          </tr>
                          </tbody>
                        </table></div>
                      <Pagination
                          v-if="pagination"
                          :payload="payload"
                          :pagination="pagination"
                          :req-data="reqData">
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="content" class="" v-else>
    <div class="wrapPage v_deep_mail">
      <ul class="tab-list">
        <li><a>베팅내역</a></li>
        <li><a>고객센터</a></li>
        <li class="active">
          <router-link :to="{ name: '쪽지-목록' }">쪽지</router-link>
        </li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>쪽지</div>
          <span>Inbox</span>
        </div>
        <div>
          <div class="inbox-infolist">
<!--            <div class="txt">전체받은 쪽지 <span>96</span> 건</div>-->
            <div class="choice-list"><a @click="readAllNotes" class="icon-mail-read"></a></div>
          </div>
          <div class="content-table">
            <table>
              <thead>
              <tr>
                <th class="w20">상태</th>
                <th class="w40">제목</th>
                <th class="w20">받은시간</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in lists" :key="'notes'+index " @click="movePage(row.seq)">
                <td class="w20">
                  <div class="icon-mail-read-no">
                    <span v-if="!row.readAt">읽기전</span>
                    <span v-else>읽기전</span>
                  </div>
                </td>
                <td class="w40 left">{{ row.title }}</td>
                <td class="w20">{{ row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="absolute! top-0"></div>
          <MPagination
              v-if="pagination"
              :payload="payload"
              :pagination="pagination"
              :req-data="reqData">
          </MPagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from '../../components/Pagination01'
import MPagination from '../../components/MPagination'

export default {
  name: "Notes",
  props: ['isMobile'],
  components: {
    Pagination,
    MPagination
  },
  data: function () {
    return {
      path: 'notes/list',
      lists: [],
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null
      },
      openDetailNotes: [],
    }
  },
  mounted: function () {
    this.reqData()
  },
  methods: {
    reqData() {
      this.$store.dispatch('GET_NOTES', {payload: this.payload})
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          })
          .catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    readNotes: function (item, index) {
      this.toggle(index)
      this.$store.commit('READ', item)
      this.$store.dispatch('READ_NOTES', {payload: {item}}).then(() => {
        this.$store.dispatch('ME');
        this.reqData();
      })
    },
    deleteNotes: function (item) {
      if (confirm('삭제 하시겠습니까?')) {
        return this.$store.dispatch('DELETE_NOTES', {payload: {item}})
            .then(data => {
              const result = data.data;
              alert(result.msg);
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error || '관리자에게 문의하세요!');
            })
      }
    },
    deleteAllNotes: function () {
      if (confirm('전체 삭제 하시겠습니까?')) {
        return this.$store.dispatch('DELETE_ALL_NOTES')
            .then(() => {
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error || '관리자에게 문의하세요!');
            })
      }
    },
    readAllNotes: function () {
      if (confirm('전부 확인 하시겠습니까?')) {
        this.$store.commit('READ_ALL')
        this.$store.dispatch('READ_ALL_NOTES')
            .then(() => {
              this.$store.dispatch('ME');
              this.reqData();
            }).catch(err => {
          alert(err.response.data.error || '관리자에게 문의하세요!');
        })
      }
    },
    movePage: function (seq) {
      this.$router.push({name: `쪽지-자세히`, params: { seq }})
    },
    renderHtml: function (str) {
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },

    toggle: function (index) {
      if (this.openDetailNotes.indexOf(index) >= 0) return this.openDetailNotes.splice(this.openDetailNotes.indexOf(index), 1)
      this.openDetailNotes.push(index)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailNotes.splice(index, 1);
      // return this.openDetailBet;
    },

    checkArray: function (id) {
      let result = this.openDetailNotes.indexOf(id) >= 0
      return result
    },
  }
}
</script>

<style scoped>
.inbox-infolist {
  padding: 0vw 3.4vw 1.4vw;
  font-size: .78rem;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 1.5vw
}

.inbox-infolist .choice-list a.icon-mail-read {
  margin-right: 1.4vw;
  font-size: 1.2rem
}

.inbox-infolist .choice-list {
  padding: 0
}

.inbox-infolist .txt {
  width: 75vw;
  color: var(--txtColor13)
}

.inbox-infolist .txt span {
  color: var(--txtColor16)
}

table tbody tr td .icon-mail-read {
  color: var(--txtColor18);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-size: .93rem
}

table tbody tr td .icon-mail-read-no {
  color: var(--txtColor33);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-size: .96rem
}

table tbody tr td .icon-mail-read-no:before, table tbody tr td .icon-mail-read:before {
  margin-right: .4vw
}

table tbody tr td .icon-mail-read-no span {
  font-weight: 600;
  font-size: .78rem;
  color: var(--txtColor33)
}

table tbody tr td .icon-mail-read span {
  font-size: .78rem;
  color: var(--txtColor18)
}

table tbody tr td.icon-remove {
  color: var(--txtColor12)
}

table tbody tr td.icon-remove.on {
  color: var(--txtColor26)
}

table tbody tr td.icon-remove:before {
  font-size: .93rem
}

button.allread-button {
  padding: 2vw;
  border-radius: .5vw;
  -webkit-border-radius: .5vw;
  -moz-border-radius: .5vw;
  -ms-border-radius: .5vw;
  -o-border-radius: .5vw;
  color: var(--txtColor01);
  background: var(--bgColor24)
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  table tbody tr td .icon-mail-read span, table tbody tr td .icon-mail-read-no span, .inbox-infolist, button.allread-button {
    font-size: 1.25rem
  }

  table tbody tr td.icon-remove:before, table tbody tr td .icon-mail-read-no, table tbody tr td .icon-mail-read {
    font-size: 1.6rem
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  table tbody tr td .icon-mail-read, table tbody tr td .icon-mail-read span, table tbody tr td .icon-mail-read-no span, table tbody tr td .icon-mail-read-no, .inbox-infolist, button.allread-button {
    font-size: 1.8rem
  }

  table tbody tr td.icon-remove:before, table tbody tr td .icon-mail-read-no, table tbody tr td .icon-mail-read {
    font-size: 2.2rem
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  table tbody tr td .icon-mail-read-no, table tbody tr td .icon-mail-read {
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .inbox-infolist {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .inbox-infolist .txt {
    width: 100vw;
    text-align: center
  }

  button.allread-button {
    width: 100vw;
    margin: 2vw 0
  }
}


</style>
