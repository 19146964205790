<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="casis-bn" style="width: 1330px !important; margin-top: 50px;">
      <Swiper>
        <Swiper-slide><img style="width: 1200px; height: 243px;" src="@/assets/img/etc/bn.jpg"></Swiper-slide>
        <Swiper-slide><img style="width: 1330px; height: 243px;" src="@/assets/img/etc/777.png"></Swiper-slide>
      </Swiper>
    </div>

    <div class="casis" style="width: 1330px !important;">
      <div class="side">
        <div class="search">
          <input type="text" class="inp" placeholder="게임찾기">
          <button class="btn-srch"></button>
        </div>
        <ul class="nav">
          <li><a class="active" @click="movePage('casino/home')">전체</a></li>
          <li><a @click="openCasino('evolution')">에볼루션 카지노</a></li>
          <li><a @click="openCasino('asia')">아시안게이밍 카지노</a></li>
        </ul>
      </div>
      <div class="ca-cont">
        <ul class="tabs">
          <li><a @click="movePage('casino/home')" class="on">전체</a></li>
        </ul>

        <div class="ca-list">
          <ul>
            <li v-for="(game, index) in lists" :key="'casinoList' + index">
              <div class="game_thum">
                <img :src="game.img">
                <p>{{ game.name }}</p>
                <span><a @click="openCasino(game.action)">Play now</a></span>
              </div>
              <p>{{ game.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>카지노</h2>

    </div>
    <div class="casis" style="margin-top: 15px;">
      <div class="ca-list" style="width: 100%;">
        <ul>
          <li v-for="(game, index) in lists" :key="'mCasinoList' + index">
            <a @click="openCasino(game.action)">
              <div class="game_thum">
                <img style="height: 100%;" :src="game.img">
                <p>{{ game.name }}</p>
              </div>
            </a>
            <p>{{ game.name }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {mapState} from "vuex";
import MSG from '../../../contants/msg'
export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide
  },
  data: function(){
    return {
      lists: [],
      gameList: [
        {name: '에볼루션', action: 'evolution', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tile_evolution.jpg',},
        {name: '아시안게이밍',  action: 'asia', img: 'https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/tile_asiagame.jpg',}
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
  },
  mounted() {
    this.goToTop()
    this.lists = this.gameList;
  },
  methods: {
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    goToTop: function(){
      window.scrollTo(0,0);
    },
  }
}
</script>

<style scoped>
</style>
