<template>
  <section>
<!--    <h2 class="subTitle">섹시게이밍</h2>-->


    <div class="nbox">
      <SideRight :g-type="gType"></SideRight>
      <div class="contLeft">
        <div class="gameBox" v-if="url">
          <iframe scrolling="no" frameborder="0"
                  style="width: 1000px; height: 800px;
              margin:0px 0px 0px 0px; z-index: 1; border: none;"
                  allowFullScreen="true"
                  :src="url">
          </iframe>
        </div>

      </div>

    </div>

  </section>
</template>

<script>
import SideRight from '../../../components/Game/Casino/SideRight'

export default {
  name: "Roulette",
  components: {
    SideRight
  },
  data: function(){
    return {
      path: 'token/user',
      hash: null,
      gType: 'sexygaming',
      url: null
    }
  },
  created: function(){
    this.initGame();
  },
  methods: {
    initGame: async function(){
      return this.$store.dispatch('auth/post', {path: this.path })
          .then(data => {
            const result = data.data;
            this.hash = result.hash;
            this.uid = result.uid;
            this.initUrl()
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    initUrl: function(){
      this.url = 'https://api.tgame365.com/api/';
      this.url += `?gType=${this.gType}`;
      this.url += `&uid=${this.uid}`;
      this.url += `&hash=${this.hash}`;
    }
  }
}
</script>

<style scoped>

</style>
