import AuthenticationService from "@/services/authentication.service";


const getDefaultState = () => {
    return {
        event_popup: [],
    }
}

const state = getDefaultState()
const getters = {
};
const mutations = {
    CLOSE_POPUP({ commit }, { seq }) {
        commit('SET_POPUP_VISIBILITY', { seq, isVisible: false });
    },
    SET_POPUP_VISIBILITY(state, { seq, isVisible }) {
    const popup = state.event_popup.find(p => p.seq === seq);
    if (popup) {
        popup.isVisible = isVisible;
    }
    },
    SET_POPUP_DATE(state, { seq, date }) {
        const popup = state.event_popup.find(p => p.seq === seq);
        if (popup) {
            popup.isSetDate = date;
        }
    },
    INIT_POPUP(state, data) {
        state.event_popup = data;
    },
}
const actions = {
    GET_POPUP({commit}, params) {
        const popups = localStorage.getItem('popups');
        if (popups) {
            return commit('INIT_POPUP', popups)
        }
        return AuthenticationService.get('/setting/popup/newbet', params).then((response) => {
            return commit('INIT_POPUP', response.data.payload.popups)
        })
    },
    CLOSE_MODAL({ commit }, { seq }) {
        commit('SET_POPUP_VISIBILITY', { seq, isVisible: false });
        // commit('SET_POPUP_DATE', { seq, date });
        // 로컬 스토리지에 상태 저장
    },
    DAY_INVISIBLE({ commit }, { seq }) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        commit('SET_POPUP_DATE', { seq, date });
        commit('SET_POPUP_VISIBILITY', { seq, isVisible: false });
        // 로컬 스토리지에 상태 저장
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
