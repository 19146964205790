<template>
  <div class="vfm vfm--inset vfm--fixed" style="z-index: 1000;" v-if="isOpen">
    <!--v-if-->
    <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none NAV_log"
         aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
        <div class="vfm__content pupop_content fadeIn animated">
          <div class="popWrapper">
            <h5 class="XX" @click="$store.dispatch('INVISIBLE_MODAL', 'login')">
              <span class="icon-iconCross"></span>
            </h5>
              <div class="leftZone">
                  <div class="pic">
                    <img src="@/assets/images/etc/popLogPic.png">
                  </div>
              </div>
              <div class="rightZone">
                  <div class="top">
                    <img src="../../assets/img/main/main_toplogo.png"></div>
                    <div class="title">
                      <h2>로그인</h2>
                    </div>
                  <form>
                    <div class="up">
                      <div class="input_zone">
                        <div class="inputFrame">
                          <div class="info">
                            <span class="icon-icconMan ficon"></span>
                            <div class="txtT">아이디</div>
                          </div>
                          <div class="input_content">
                            <input name="account" type="text" class="account txtColor010 bgColor09 bdColor03" placeholder="아이디" required="" v-model="signInForm.userId">
                            <div class="check">
                              <span class="icon-iconCheck"></span>
                            </div>
                          </div>
                        </div>
                        <div class="inputFrame">
                          <div class="info">
                            <span class="icon-icconPW ficon"></span>
                            <div class="txtT">비밀번호</div>
                          </div>
                          <div class="input_content">
                            <input class="pw txtColor010 bgColor09 bdColor03" type="password" placeholder="비밀번호" name="password" v-model="signInForm.userPw">
                          </div>
                        </div>
                      </div>
                      <div class="input_group rem_check">
                        <input id="rem_check" type="checkbox" name="isRemember" value="true">
                        <label for="rem_check" class="rem_check">아이디저장</label>
                      </div>
                      <div class="btnZone">
                        <div class="btnFrame">
                          <button type="button" class="btn_p blueLBtn" @click="doLogin">로그인</button>
                          <!--<button type="button" class="btn_p goUnmember blueLBtn01">비회원 문의</button>-->
                        </div>
                        <div class="txtZone">
                          <a></a>
                          <a @click="$store.dispatch('SHOW_MODAL', 'signup')" class="goREG">
                            회원가입? <span>회원가입</span>
                            </a>
                        </div>
                      </div>
                    </div>
                  </form>
              </div>
          </div>
        </div>
    </div>
</div>
</template>

<script>
//import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import { mapState } from "vuex";

export default {
  name: "LoginModal",
  data(){
    return {
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'day',
        version: null,
      },
      proceeding: false,
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.modals.login,
    }),
  },
  methods: {
    doLogin: function() {
      if(!this.signInForm.userId) return alert(MSG.error.emptyId);
      if(!this.signInForm.userPw) return alert(MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              alert(MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.$router.push({ name: '메인' })
              this.$store.dispatch('INVISIBLE_MODAL', 'login')
            }
          })
          .catch((err) => {
            alert(err.response.data.error || '관리자에게 문의해주세요!');
          })
    },
  }
}
</script>


<style scoped>

</style>
