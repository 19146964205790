<template>
  <div style="margin-top:120px;" v-if="!isMobile">
    <div class="main_content_continer" version="1.1.114">
      <div class="main_content_continer" style="min-height: 1349px;">
        <iframe  v-if="isLoading" allowfullscreen="true" height="1349" id="iFrame"
        webkitallowfullscreen="true" mozallowfullscreen="true" width="100%" :src="url"></iframe>
      </div><!--v-if-->
    </div>
  </div>
  <div v-else style="margin-top: 50px;">
    <div class="main_content_continer" version="1.1.114">
      <iframe  v-if="isLoading" allowfullscreen="true" height="1349" id="iFrame"
      webkitallowfullscreen="true" mozallowfullscreen="true" width="100%" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotusBaccarat1",
  props: ['isMobile'],

  data: function () {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    authToken(){
      if (!this.user) return null;
      return JSON.parse(localStorage.getItem('token')).jwt_token
    },
    url(){
      // let url = 'http://127.0.0.1:3080/';
      let url = 'https://desktop.lime-00.com/#/mini/lotus/baccarat1';
      if (this.isMobile) url = 'https://mobile.lime-00.com/#/mini/lotus/baccarat1';
      let siteName, authToken;
      siteName = 'newbet';
      authToken = this.authToken;
      if (this.user){
        siteName = this.user.members_sitename;
      }
      url += `?siteName=${siteName}&authToken=${authToken}`;

      return url
    }
  },
  mounted() {
    setTimeout(()=>{ this.isLoading = !this.isLoading;}, 2000);
  }
}
</script>

