<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="Logo"></span><span
                  >고객센터</span></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice deep_wrap_notice">
                    <div class="noticeFrame serviceFrame messageFrame">
                      <div>
                        <div class="serviceOuter">
                          <div class="serviceArea">
                            <div class="tAreaFrame">
                              <div class="txt">제목：</div>
                              <div class="areaFrame">
                                <input placeholder="문의할 내용을 입력 하세요." style="width: 98%;" v-model="writePayload.title"/>
                              </div>
                            </div>
                            <div class="tAreaFrame">
                              <div class="txt">내용：</div>
                              <div class="areaFrame">
                                <textarea placeholder="문의할 내용을 입력 하세요." v-model="writePayload.content"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="btnZone">
                            <button class="btnP goSend goldLBtn blueLLineBtn01" @click="processWrite">문의요청</button>
                          </div>
                        </div>
                        <div class="listZone messageZone">
                          <table>
                            <thead></thead>
                            <tbody>
                            <tr>
                              <th class="ac" width="15%">번호</th>
                              <th class="ac" width="40%">내용</th>
                              <th class="ac" width="15%">작성자</th>
                              <th class="ac" width="15%">상태</th>
                              <th class="ac" width="15%">업데이트 시간</th>
                            </tr>
                            <tr class="mailUse able" v-for="row in lists" :key="'mailUse'+row.board_seq">
                              <td class="ac">
                                <router-link :to="{name: '고객센터-자세히', params: { seq: row.board_seq }}">
                                  {{ row.board_seq }}
                                </router-link>
                              </td>
                              <td class="al">
                                <router-link :to="{name: '고객센터-자세히', params: { seq: row.board_seq }}">
                                  {{ row.board_title }}
                                </router-link>
                              </td>
                              <td class="ac">
                                <router-link :to="{name: '고객센터-자세히', params: { seq: row.board_seq }}">
                                  {{ row.board_author_name }}
                                </router-link>
                              </td>
                              <td class="ac">
                                <router-link :to="{name: '고객센터-자세히', params: { seq: row.board_seq }}" >
                                  <span :class="{'done': row.replyDate}">{{ row.replyDate ? '답변완료' : '답변대기' }}</span>
                                </router-link>
                              </td>
                              <td class="ac">
                                <router-link :to="{name: '고객센터-자세히', params: { seq: row.board_seq }}">
                                  {{ row.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
                                </router-link>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="" style="" v-if="lists.length === 0">
                            <div class="noDataFrame">
                              <div class="pic"><img src="@/assets/images/noData.png" alt="no-data"></div>
                              <h2>데이터 없음</h2>
                              <h3>Sorry, Check no data</h3></div>
                          </div>
                        </div>
                        <Pagination
                            v-if="pagination"
                            :payload="payload"
                            :pagination="pagination"
                            :req-data="reqData">
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section v-else id="content" class="">
    <div class="wrapPage v_deep_service_center">
      <ul class="tab-list">
        <li>
          <router-link :to="{name: '베팅내역'}">베팅내역</router-link>
        </li>
        <li class="active"><a>고객센터</a></li>
        <li>
          <router-link :to="{name:'쪽지-목록'}">쪽지</router-link>
        </li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>고객센터</div>
          <span>Customer</span></div>
        <div class="full-table customer-table">
          <table class="customer">
            <tbody>
            <tr>
              <th class="w25">제목</th>
              <td class="w75">
                <input placeholder="제목을 입력 하세요." v-model="writePayload.title">
              </td>
            </tr>
            <tr>
              <th class="w25">내용</th>
              <td class="w75">
                <textarea cols="30" rows="10" placeholder="문의할 내용을 입력 하세요." v-model="writePayload.content"></textarea>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="btn-center">
            <button class="write-button customer-send-btn" @click="processWrite">문의요청</button>
          </div>
        </div>
        <div class="customer-table">
          <div class="content-table">
            <table>
              <thead>
              <tr>
                <th class="w55">제목</th>
                <th class="w25">업데이트 시간</th>
                <th class="w25">상태</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="lists.length === 0">
                <td colspan="5">
                  <p class="noData" style="">
                    <img src="@/assets/images/noData-img.png" alt="no-data">
                    <b>데이터 없음</b>
                    <span>Sorry, Check no data</span>
                  </p>
                </td>
              </tr>
              <template>
                <tr v-for="(row, index) in lists" :key="'customer_board '+ index" @click="movePage(row.board_seq)">
                  <td>{{ row.board_title }}</td>
                  <td>{{ row.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                  <td>{{ row.replyDate ? '답변완료' : '대기중' }}</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="absolute! top-0"></div>
          <MPagination
              v-if="pagination"
              :payload="payload"
              :pagination="pagination"
              :req-data="reqData">
          </MPagination>
        </div>
      </div>
    </div>
  </section>
  <!--  <section v-else id="content" class="">-->
  <!--    <div class="wrapPage">-->
  <!--      <ul class="tab-list">-->
  <!--        <li class="active"><a>공지</a></li>-->
  <!--        <li><a>이벤트</a></li>-->
  <!--      </ul>-->
  <!--      <div class="wrapper">-->
  <!--        <div class="title-p">-->
  <!--          <div>공지</div>-->
  <!--          <span>Notification</span></div>-->
  <!--        <div class="content-table">-->
  <!--          <table>-->
  <!--            <thead>-->
  <!--            <tr>-->
  <!--              <th class="w20"> No.</th>-->
  <!--              <th class="w60">제목</th>-->
  <!--              <th class="w20">작성자</th>-->
  <!--            </tr>-->
  <!--            </thead>-->
  <!--            <tbody>-->
  <!--            <tr>-->
  <!--              <td class="w20">-->
  <!--                <div class="table-btn important">중요</div>-->
  <!--              </td>-->
  <!--              <td class="w60 left">[필독] 🚨사칭 주의보🚨</td>-->
  <!--              <td class="w20"><img src="/assets/logo-5KdY4KVu.svg" class="logo">-->
  <!--              </td>-->
  <!--            </tr>-->
  <!--            <tr>-->
  <!--              <td class="w20">-->
  <!--                <div class="table-btn important">중요</div>-->
  <!--              </td>-->
  <!--              <td class="w60 left">1:1 전용계좌 유의사항 안내</td>-->
  <!--              <td class="w20"><img src="/assets/logo-5KdY4KVu.svg" class="logo">-->
  <!--              </td>-->
  <!--            </tr>-->
  <!--            <tr>-->
  <!--              <td class="w20">-->
  <!--                <div><span>1</span></div>-->
  <!--              </td>-->
  <!--              <td class="w60 left">사바스포츠 - 이용 방법</td>-->
  <!--              <td class="w20"><img src="/assets/logo-5KdY4KVu.svg" class="logo">-->
  <!--              </td>-->
  <!--            </tr>-->
  <!--            <tr>-->
  <!--              <td class="w20">-->
  <!--                <div><span>2</span></div>-->
  <!--              </td>-->
  <!--              <td class="w60 left">BET38 서비스약관 (라이센스)</td>-->
  <!--              <td class="w20"><img src="/assets/logo-5KdY4KVu.svg" class="logo">-->
  <!--              </td>-->
  <!--            </tr>-->
  <!--            <tr>-->
  <!--              <td class="w20">-->
  <!--                <div><span>3</span></div>-->
  <!--              </td>-->
  <!--              <td class="w60 left">BET38 이용 규정</td>-->
  <!--              <td class="w20"><img src="/assets/logo-5KdY4KVu.svg" class="logo">-->
  <!--              </td>-->
  <!--            </tr>&lt;!&ndash;&ndash;&gt;</tbody>-->
  <!--          </table>-->
  <!--        </div>-->
  <!--        <div class="absolute! top-0"></div>-->
  <!--        <div class="pagination">-->
  <!--          <ul>-->
  <!--            <li class="first"><a href="javascript: void(0)">처음</a></li>-->
  <!--            <li class="prev"><a href="javascript: void(0)">이전</a></li>-->
  <!--            <li class="pages on"><a href="javascript: void(0)">1</a></li>-->
  <!--            <li class="next"><a href="javascript: void(0)">다음</a></li>-->
  <!--            <li class="last"><a href="javascript: void(0)">끝</a></li>-->
  <!--          </ul>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
</template>

<script>
// import SideLink from '../../../components/Board/SideLink'
// import Loading from '../../../components/LoadingGray'

import Pagination from '../../../components/Pagination01'
import MPagination from '@/components/MPagination'

import MSG from '@/contants/msg';

export default {
  name: "Customer",
  props: ['isMobile'],
  components: {
    Pagination,
    MPagination,
    // SideLink,
    // Loading
  },
  data: function () {
    return {
      path: 'bbs/c/l',
      lists: [],
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null
      },
      expandedArticle: [],
      writePayload: {
        title: null,
        content: null,
        betting: null,
      },
    }
  },
  mounted: function () {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function (payload) {
      return this.$store.dispatch('GET_SUPPORT_LIST', {payload})
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
    movePage: function (page) {
      this.$router.push({name: `고객센터-자세히`, params: {seq: page}})
    },
    renderHtml: function (str) {
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0) return this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1)
      this.expandedArticle.push(index)
    },
    accountReq: function () {
      if (!confirm(MSG.accReqMsg)) return;
      this.$store.dispatch('GET_ACCOUNT_REQ')
          .then(data => {
            if (data.data.success) {
              this.reqData(this.payload)
              alert(data.data.msg)
            }
          }).catch(err => {
        alert(err.response.data.error || '관리자에게 문의하세요!');
      })
    },
    deleteArticle: function (item) {
      if (!confirm('삭제 하시겠습니까?')) return;
      const payload = {
        item
      };
      this.$store.dispatch('DELETE_ARTICLE', {payload})
          .then(data => {
            if (data.data.success) {
              this.reqData(this.payload)
              alert(data.data.msg)
            }
          }).catch(err => {
        alert(err.response.data.error || '관리자에게 문의하세요!');
      })
    },
    checkArray: function (id) {
      let result = this.expandedArticle.indexOf(id) >= 0
      return result
    },
    processWrite: function () {
      if (this.processing) return alert('등록중 입니다.')
      if (!this.writePayload.title || this.writePayload.title === '' || this.writePayload.content === '' || !this.writePayload.content) return alert('제목과 내용을 확인해주세요.')
      this.processing = true;
      return this.$store.dispatch('PROCESS_SUPPORT', {payload: this.writePayload})
          .then(data => {
            const result = data.data;
            alert(result.msg);
            this.$router.push({name: '고객센터-목록'})
          }).catch(err => {
            alert(err.response.data.error || '관리자에게 문의하세요!');
          })
    },
  }
}
</script>

<style scoped>
@media (min-width: 500px) {
  :root {
    --font-family: "Noto Sans KR"
  }

  .homePage {
    animation: none !important
  }

  .noticeContent {
    background-size: 100%;
    background-repeat: repeat-y;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-repeat: repneat-y;
    transition: .4s ease-out;
    position: relative
  }

  .webp .noticeContent {
    background-image: url(../../../assets/homeBG.webp)
  }

  .no-webp .noticeContent {
    background-image: url(../../../assets/homeBG.jpg)
  }

  .aniFrame {
    animation: leftIn-ba24923c .4s;
    animation-fill-mode: forwards;
    opacity: 0
  }

  .footer_notice {
    animation: downIn-ba24923c .4s;
    animation-fill-mode: forwards;
    opacity: 0
  }

  .titlePic {
    background-repeat: no-repeat;
    width: 1440px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px
  }

  .titlePic .titlePwrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
  }

  .titlePic .titlePwrapper .leftZone {
    color: var(--txtColor71);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: start;
    transform: translateZ(0);
    margin-right: 1vw
  }

  .titlePic .titlePwrapper .leftZone span.icon-iiconLogoB {
    font-size: 2.3vw;
    margin-right: 4px;
    display: none
  }

  .titlePic .titlePwrapper .leftZone h4 {
    font-size: 1.4vw;
    margin-right: .5vw;
    display: none
  }

  .titlePic .titlePwrapper .leftZone span {
    color: var(--txtColor_019);
    font-size: 1.4vw;
    font-family: var(--font-family) !important;
    font-weight: 700 !important;
    white-space: nowrap
  }

  .titlePic .titlePwrapper .line {
    height: 2px;
    width: 100vw;
    background-color: var(--bgColor_015);
    position: relative
  }

  .titlePic .titlePwrapper .line:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: var(--bgColor_015);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%)
  }

  .main_content_wrap_notice {
    width: 1440px !important
  }

  .noticeFrame {
    width: 100%;
    height: auto;
    padding-bottom: 22px
  }

  .noticeFrame .title {
    color: var(--txtColor22);
    font-size: 26px;
    font-weight: 600
  }

  .noticeFrame .title span {
    color: var(--txtColor23);
    font-size: 14px;
    margin-left: 7px
  }

  .main_content_wrap_notice .listZone {
    width: 100%;
    margin-top: 0
  }

  .main_content_wrap_notice .listZone table {
    width: 100%
  }

  .main_content_wrap_notice .listZone table tr {
    background-color: var(--main);
    transition: .4s ease-out;
    border: 1px solid var(--bdColor070)
  }

  .main_content_wrap_notice .listZone table tr:nth-child(odd) {
    background-color: var(--bgColor150) !important
  }

  .main_content_wrap_notice .listZoneA table tr:nth-child(odd) {
    background-color: var(--bgColor_02) !important
  }

  .main_content_wrap_notice .listZone table tr:first-child, .main_content_wrap_notice .listZone table tr:first-child:hover {
    background-color: var(--bgColor_02) !important;
    color: var(--txtColor_03)
  }

  .main_content_wrap_notice .listZone table tr th {
    padding: 10px 0;
    font-size: 14px;
    border-bottom: 1px solid var(--bdColor047);
    position: relative
  }

  .main_content_wrap_notice .listZone table tr th:first-child:before {
    content: "";
    width: 1px;
    height: 21px;
    background-color: var(--bdColor070);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none
  }

  .main_content_wrap_notice .listZone table tr th:after {
    content: "";
    width: 1px;
    height: 21px;
    background-color: var(--bdColor070);
    position: absolute;
    right: 0;
    bottom: 0
  }

  .main_content_wrap_notice .listZone table tr th.number {
    width: 9%
  }

  .main_content_wrap_notice .listZone table tr th.name {
    width: 16%
  }

  .main_content_wrap_notice .listZone table tr th.date {
    width: 14%
  }

  .main_content_wrap_notice .listZone table tr th:last-child:after {
    display: none
  }

  .main_content_wrap_notice .listZone table tr td {
    color: var(--txtColor_017);
    font-size: 13px;
    padding: 21px 19px;
    transition: all .3s ease-out;
    position: relative;
    font-family: var(--font-family) !important;
    font-weight: 400 !important;
    line-height: 1.6
  }

  .main_content_wrap_notice .messageZone table tr td {
    padding: 0
  }

  .main_content_wrap_notice .listZone table tr td .btnM1 {
    padding: 13px 0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: .4s ease-out
  }

  .main_content_wrap_notice .listZone table tr td.fc {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .main_content_wrap_notice .listZone table tr td.back {
    color: var(--txtColor_05) !important
  }

  .main_content_wrap_notice .listZone table tr td span.textOver {
    color: var(--txtColor_017);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 143px;
    display: block;
    font-family: var(--font-family) !important;
    font-weight: 400 !important
  }

  .main_content_wrap_notice .listZone table tr td a {
    color: var(--txtColor_017);
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    font-family: var(--font-family) !important;
    font-weight: 400 !important
  }

  .main_content_wrap_notice .messageZone table tr td a {
    padding: 21px 19px
  }

  .main_content_wrap_notice .listZone table tr td a.goResult {
    color: var(--txtColor02);
    font-size: 14px;
    display: block;
    background-color: var(--btnColor038);
    padding: 12px 0;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s all ease-out
  }

  .main_content_wrap_notice .listZone table tr td a.goResult:hover {
    background-color: var(--btnColor038_h)
  }

  .main_content_wrap_notice .listZone table tr td a.goNotice {
    font-size: 12px;
    color: var(--txtColor013);
    background-color: var(--btnColor09);
    display: block;
    width: 50px;
    height: 25px;
    line-height: 25px;
    border-radius: 3px;
    margin: 0 auto;
    font-weight: 700;
    transition: .4s ease-out
  }

  .main_content_wrap_notice .listZone table tr td a.goNotice:hover {
    background-color: var(--btnColor09_h)
  }

  .main_content_wrap_notice .listZone table tr td span {
    color: var(--txtColor_017)
  }

  .main_content_wrap_notice .listZone table tr td h5 {
    display: none
  }

  .main_content_wrap_notice .listZone table tr td img.logo {
    width: 77px;
    margin: 0 auto
  }

  .main_content_wrap_notice .listZone table tr td span.icon-icconLOAD {
    color: var(--txtColor66);
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
    margin-right: 5px
  }

  .main_content_wrap_notice .listZone table tr td .btnFrame {
    display: flex;
    align-items: center
  }

  .main_content_wrap_notice .listZone table tr td .btnT {
    color: var(--txtColor02);
    background-color: var(--bgColor131);
    width: 121px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s ease-out;
    margin-left: 6px;
    border: 0px
  }

  .main_content_wrap_notice .listZone table tr td .btnT:hover {
    background-color: var(--btnColor034_h)
  }

  .main_content_wrap_notice .listZone table tr:nth-child(2n) {
    background-color: var(--main) !important
  }

  .main_content_wrap_notice .listZone table tr:nth-child(2n):hover .uLine, .messageFrame .listZone tr.mailUse:hover .uLine {
    text-decoration: underline
  }

  .main_content_wrap_notice .listZone table tr:nth-child(2n):hover .frame01, .messageFrame .listZone tr.mailUse:hover .frame01 {
    border: 1px solid var(--bdColor058)
  }

  .ac {
    text-align: center
  }

  .fc {
    display: flex
  }

  .al {
    text-align: left
  }

  .ar {
    text-align: right
  }

  .contentZone {
    margin-top: 20px
  }

  .contentZone .time {
    color: var(--txtColor116);
    font-size: 14px;
    padding: 6px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto
  }

  .contentZone .time .pageBack {
    display: flex;
    align-items: center
  }

  .contentZone .time .pageBack a {
    color: var(--txtColor_05);
    font-size: 14px;
    transition: .3s ease-out
  }

  .contentZone .time .pageBack a:hover {
    color: var(--txtColor_031)
  }

  .contentZone .time .pageBack .crossLine {
    width: 1px;
    height: 13px;
    background-color: var(--bgColor085);
    margin: 0 10px;
    display: none
  }

  .contentZone .time .title {
    color: var(--txtColor_019);
    font-size: 23px;
    font-family: var(--font-family) !important;
    font-weight: 700 !important;
    margin: 0 auto
  }

  .contentZone .time span.timeR {
    color: var(--txtColor116);
    font-size: 14px;
    margin-left: 4px;
    display: flex
  }

  .contentZone .time span.timeR .name {
    margin-right: 26px;
    position: relative
  }

  .contentZone .time span.timeR .name:after {
    content: "";
    width: 1px;
    height: 13px;
    background-color: var(--txtColor116);
    position: absolute;
    right: -12px
  }

  .contentZone .txt {
    padding: 33px;
    border: 1px solid #e4e4f7;
    background-color: var(--bgColor_024);
    color: var(--txtColor116)
  }

  .contentZone .txt h2 {
    font-size: 20px;
    color: var(--txtColor_05)
  }

  .contentZone .txt p {
    font-size: 12px;
    color: var(--txtColor116);
    margin-top: 12px;
    line-height: 1.6;
    text-align: justify;
    word-break: break-all
  }

  .contentZone .txt p img {
    width: 760px;
    height: auto;
    margin-top: 21px
  }

  .contentZone .txt p img[src*="/plugins/smiley"] {
    width: auto !important
  }

  .contentZone .txt .adPic {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .contentZone .txt .adPic img {
    width: 758px;
    height: auto
  }

  .contentZone .btnFrame {
    width: 100%;
    display: flex;
    justify-content: center
  }

  .contentZone .btnP {
    height: 40px;
    padding: 0 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    border: 0px;
    transition: .2s ease-out;
    background: var(--bgColor_02)
  }

  .contentZone .btnP:hover {
    background: var(--btnColor_001) !important;
    color: var(--txtColor01_h) !important
  }

  .messageFrame .frame .hoverMsg {
    background-color: var(--bgColor070);
    position: absolute;
    bottom: -43px;
    z-index: 1;
    white-space: nowrap;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid var(--bdColor038);
    color: var(--txtColor66);
    display: none
  }

  .messageFrame .frame .hoverMsg:after {
    content: "";
    background-image: url(data:image/webp;base64,UklGRsgCAABXRUJQVlA4WAoAAAAwAAAABwAABgAASUNDUBgCAAAAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANkFMUEg5AAAADAEBASsC1QD/AQAPOf/J8gECAkr+/wK6/gM1HAAAFuTQGiP7AAA4AOJLBAAAABI89EwAAAAAADQVAFZQOCBIAAAAMAIAnQEqCAAHAAFAJiWMAnQGKAcuCpelRAAA/vkBVVqdobUD5QNUX1lVKDpi3D/XLpRnBXHTs9i0luuMVSxPeAI46UCZAAAA);
    width: 8px;
    height: 7px;
    position: absolute;
    top: -11%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  .webp .messageFrame .frame .hoverMsg:after {
    background-image: url(data:image/webp;base64,UklGRsgCAABXRUJQVlA4WAoAAAAwAAAABwAABgAASUNDUBgCAAAAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANkFMUEg5AAAADAEBASsC1QD/AQAPOf/J8gECAkr+/wK6/gM1HAAAFuTQGiP7AAA4AOJLBAAAABI89EwAAAAAADQVAFZQOCBIAAAAMAIAnQEqCAAHAAFAJiWMAnQGKAcuCpelRAAA/vkBVVqdobUD5QNUX1lVKDpi3D/XLpRnBXHTs9i0luuMVSxPeAI46UCZAAAA)
  }

  .no-webp .messageFrame .frame .hoverMsg:after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAYAAAA1WQxeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDc1MywgMjAyMS8wMi8xNS0xMTo1MjoxMyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5QjhERTZDNjk0MDExRUM5NzNCQTAwNUZCQjM2QjZBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5QjhERTZENjk0MDExRUM5NzNCQTAwNUZCQjM2QjZBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlCOERFNkE2OTQwMTFFQzk3M0JBMDA1RkJCMzZCNkEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlCOERFNkI2OTQwMTFFQzk3M0JBMDA1RkJCMzZCNkEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz57ULtdAAAAuElEQVR42mJcvvUMIwMDAxjzsEhqMzD8Z/ry58VVBgj4zwKT5GIWEWBkYPQEMhk5mYWeff/77hNIBUgBExMjCzMzI6ufjgaXD0jw8o3/rxkZmNb8Z/j3D6SAGajbQlWJM0REmMUGpEBNifPTzbsij77+fXWOCWicFFC1rZgoqwXUXgYQG2iqBTsTrxQTCyO7t742lz8rC6MQTAGIDRTzY2XicWJRV+HyERRgMWFAAyAxDRWutwABBgDkKiV8h1c1qgAAAABJRU5ErkJggg==)
  }

  .messageFrame .frame:hover .hoverMsg {
    display: block
  }

  .messageFrame .frame {
    width: 35px;
    height: 35px;
    border: 1px solid var(--bdColor038);
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-out;
    position: relative
  }

  .messageFrame .editMessage {
    margin-top: 30px;
    display: flex;
    align-items: center
  }

  .messageFrame .editMessage span {
    color: var(--txtColor_017);
    font-size: 17px;
    cursor: pointer;
    transition: .4s ease-out
  }

  .messageFrame .editMessage .frame:hover, .messageFrame .editMessage .frame.active {
    border: 1px solid var(--bdColor043)
  }

  .messageFrame .editMessage .frame:hover span, .messageFrame .editMessage .frame.active span {
    color: var(--txtColor_017)
  }

  .messageFrame .listZone {
    margin-top: 17px
  }

  .messageFrame .listZone tr.mailUse {
    transition: .4s ease-out
  }

  .messageFrame .listZone tr.mailUse:hover {
    background-color: var(--bdColor039)
  }

  .messageFrame .listZone tr.mailUse td {
    border-left: 0;
    border-right: 0
  }

  .messageFrame .listZone tr.mailUse td .frame01 {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-out;
    text-align: center;
    margin: 0 auto;
    border: 1px solid var(--bdColor038);
    background-color: var(--main);
    border-radius: 5px
  }

  .messageFrame .listZone tr.mailUse td .frame01:hover, .messageFrame .listZone tr.mailUse td .frame01.active {
    border: 1px solid var(--bdColor043)
  }

  .messageFrame .listZone td.checkUse .rem_check {
    margin-right: -3px
  }

  .messageFrame .listZone td.checkUse .iconFrame {
    display: flex;
    align-items: center
  }

  .messageFrame .listZone td.checkUse .iconFrame span {
    font-size: 18px;
    font-weight: 100;
    margin-right: 8px
  }

  .messageFrame .listZone td.checkUse .iconFrame h4 {
    margin-top: 3px
  }

  .messageFrame .listZone td span.godeleteMail {
    font-size: 16px;
    font-weight: 100;
    cursor: pointer;
    color: var(--txtColor_017)
  }

  .balanceH .fundFrame .moneyBtns .search {
    color: var(--txtColor02);
    border: none
  }

  .fundFrame .tabZone {
    margin-bottom: 15px
  }

  .fundFrame .tabZone ul {
    display: flex
  }

  .fundFrame .tabZone ul li {
    height: 47px;
    background-color: var(--main);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s ease-out;
    position: relative;
    margin-right: 4.5%
  }

  .deposit .fundFrame .tabZone ul li {
    width: 50%;
    border: 1px solid var(--bgColor_019);
    margin-right: 0;
    margin-left: -1px
  }

  .deposit .fundFrame .tabZone ul li:hover, .deposit .fundFrame .tabZone ul li.active {
    background-color: #f79c08
  }

  .deposit .fundFrame .tabZone ul li:hover h4, .deposit .fundFrame .tabZone ul li.active h4 {
    color: var(--txtColor103)
  }

  .deposit .fundFrame .tabZone ul li:first-child {
    border-radius: 10px 0 0
  }

  .deposit .fundFrame .tabZone ul li:last-child {
    border-radius: 0 10px 0 0
  }

  .deposit .fundFrame .subZone ul li .infoZoneA {
    display: flex;
    border-bottom: 1px solid var(--bdColor070)
  }

  .deposit .fundFrame .subZone ul li .up {
    width: 17% !important;
    align-items: self-start !important;
    flex-direction: column
  }

  .deposit .fundFrame .subZone ul li .up .txtFrame {
    display: flex;
    align-items: center
  }

  .deposit .fundFrame .subZone ul li .up span.icon-iconExclamation01 {
    font-size: 19px;
    margin-right: 7px;
    color: var(--txtColor_05)
  }

  .deposit .fundFrame .subZone ul li .up .hint {
    display: flex;
    align-items: center;
    color: var(--txtColor69);
    position: relative;
    cursor: pointer;
    width: 112px;
    margin-left: 27px;
    margin-top: 11px
  }

  .deposit .fundFrame .subZone ul li .up .hint span {
    font-size: 18px;
    font-weight: 700;
    margin-right: 4px
  }

  .deposit .fundFrame .subZone ul li .up .hint h2 {
    font-size: 14px;
    font-weight: 700;
    margin-right: 6px
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg {
    background-color: var(--bgColor_016);
    padding: 21px;
    border-radius: 10px;
    width: 440px;
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: -36px;
    display: none;
    transition: .4s ease-out;
    box-shadow: var(--shadow08) .1em .1em 1.3em
  }

  .deposit .fundFrame .subZone ul li .up .hint:hover .hoverMsg {
    display: block
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg:before {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAMAAAD3n0w0AAAANlBMVEUAAAD19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fXZ4bJ0AAAAEXRSTlMA7BP129LAsXhfTkAtJAqgh51MC+cAAABOSURBVBjTbdA5EoAgAMDA4MEN6v8/a+k4JOWWIT1rzCjIdgqyHYKMIEgPgrRdkGpIMSQbchtyGZIMZ1zx28BfD0FGEKQHQdouSDWkGJJfXrQOlRv9u2sAAAAASUVORK5CYII=);
    width: 20px;
    height: 23px;
    background-repeat: no-repeat;
    position: absolute;
    left: 8px;
    bottom: -23px
  }

  .webp.deposit .fundFrame .subZone ul li .up .hint .hoverMsg:before {
    background-image: url(data:image/webp;base64,UklGRr4CAABXRUJQVlA4WAoAAAAwAAAAEwAAFQAASUNDUBgCAAAAAAIYAAAAAAIQAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANkFMUEhKAAAACWBTW1sbrJHe68SGDRxgAwlBQaKBFQPpPRq+KXNEEEjafCZ4jK4gQGxA9rUGsVILiUeE9U+EqT5EzwiS5hAlIFJChIJwTf78cgFWUDggLgAAANACAJ0BKhQAFgA+bSySRaQioZgEAEAGxLSAAD2964AA/vpOTP4L//7JSNIAAAA=)
  }

  .no-webp .deposit .fundFrame .subZone ul li .up .hint .hoverMsg:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAMAAAD3n0w0AAAANlBMVEUAAAD19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fX19fXZ4bJ0AAAAEXRSTlMA7BP129LAsXhfTkAtJAqgh51MC+cAAABOSURBVBjTbdA5EoAgAMDA4MEN6v8/a+k4JOWWIT1rzCjIdgqyHYKMIEgPgrRdkGpIMSQbchtyGZIMZ1zx28BfD0FGEKQHQdouSDWkGJJfXrQOlRv9u2sAAAAASUVORK5CYII=)
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg .title {
    color: var(--txtColor97);
    font-size: 16px
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg .section {
    width: 100%;
    height: auto
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg .section .leftZone {
    color: var(--txtColor105);
    font-size: 12px;
    float: left;
    height: 25px;
    padding: 0 8px;
    border-radius: 15px;
    background-color: var(--bgColor126);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px
  }

  .deposit .fundFrame .subZone ul li .up .hint .hoverMsg .section .rightZone {
    color: var(--txtColor02);
    font-size: 12px;
    width: 83%;
    height: auto;
    float: left;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 30px;
    margin-top: 4px
  }

  .deposit .fundFrame .subZone ul li .up .txt {
    color: var(--txtColor_05) !important
  }

  .deposit .fundFrame .subZone ul li .infoZoneA .down {
    width: 100%;
    padding: 0 0 36px;
    margin-top: -1px;
    z-index: 0
  }

  .deposit .fundFrame .subZone ul li .infoZoneA .down .section {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 31px;
    margin-left: -14px
  }

  .deposit .fundFrame .subZone ul li .infoZoneA .down .section .leftZone {
    color: var(--txtColor103);
    font-size: 14px;
    float: left;
    height: 25px;
    padding: 0 8px;
    border-radius: 15px;
    background-color: var(--bgColor126);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px
  }

  .deposit .fundFrame .subZone ul li .infoZoneA .down .section .rightZone {
    color: var(--txtColor_03);
    font-size: 14px;
    width: 83%;
    float: left;
    text-align: justify;
    margin-left: 6px
  }

  .deposit .listZoneA {
    width: 100%;
    margin-top: 30px
  }

  .fundFrame .tabZone ul li span {
    font-size: 46px;
    transition: .4s ease-out;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .fundFrame .tabZone ul li:after {
    width: 100%;
    height: 4px;
    background-color: var(--bgColor095);
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: .4s ease-out
  }

  .fundFrame .tabZone ul li:hover:after, .fundFrame .tabZone ul li.active:after {
    opacity: 1
  }

  .fundFrame .tabZone ul li h4 {
    color: var(--txtColor_05);
    font-size: 18px;
    transition: .4s ease-out
  }

  .fundFrame .tabZone ul li:hover h4, .fundFrame .tabZone ul li.active h4 {
    color: var(--txtColor75)
  }

  .fundFrame .main_content_wrap_notice .listZone {
    border-bottom: 1px solid var(--bdColor04)
  }

  .fundFrame tr td {
    border-radius: 0
  }

  .fundFrame tr:nth-child(2n):hover {
    background-color: var(--bdColor039) !important
  }

  .fundFrame td.subject {
    width: 220px;
    background-color: var(--bgColor_023) !important;
    border-right: 2px solid var(--bdColor070)
  }

  .fundFrame .btnZone {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px
  }

  .fundFrame .btnZone button {
    height: 40px;
    padding: 0 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    transition: .2s ease-out;
    background-color: var(--main);
    border: 0px
  }

  .fundFrame .main_content_wrap_notice .listZone .input_content {
    width: 12%;
    height: 30px;
    float: left;
    margin-right: 4px
  }

  .fundFrame .main_content_wrap_notice .listZone .input_content input {
    height: 30px
  }

  .fundFrame .moneyBtns {
    display: flex;
    flex-wrap: wrap;
    margin-left: 8px
  }

  .fundFrame .moneyBtns .btnM {
    height: 40px;
    padding: 0 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--txtColor105);
    cursor: pointer;
    transition: .4s ease-out;
    margin: 0 10px 10px 0;
    border-radius: 20px
  }

  .fundFrame .moneyBtns .btnM:hover {
    color: var(--txtColor103) !important;
    transition: .3s ease-out;
    cursor: pointer
  }

  .fundFrame .moneyBtns .blockB, .fundFrame .moneyBtns .blockB:hover {
    background: #555a6a !important;
    color: var(--txtColor90) !important;
    cursor: default !important;
    transition: none !important;
    border: 1px solid #555a6a !important
  }

  .fundFrame .moneyBtns .chooseBtn {
    background-color: var(--bgColor033)
  }

  .fundFrame .moneyBtns .reset:hover {
    background-color: var(--btnColor029_h)
  }

  .fundFrame .moneyBtns .chooseBtn {
    transition: .3s ease-out;
    cursor: pointer;
    background: var(--btnColor_001) !important;
    color: var(--txtColor_08) !important
  }

  .fundFrame .selectIuput {
    margin-left: 10px
  }

  .fundFrame .selectIuput select {
    width: 200px;
    height: 40px;
    padding-left: 11px;
    background-repeat: no-repeat;
    background-position: 10px;
    box-sizing: border-box;
    position: relative;
    color: var(--txtColor_03);
    background-color: var(--main);
    border: 1px solid var(--bdColor_008) !important;
    font-family: var(--font-family) !important;
    font-weight: 400 !important
  }

  .fundFrame .main_content_wrap_notice .listZone a.btn_p {
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--txtColor02);
    border-radius: 3px;
    transition: .4s ease-out;
    cursor: pointer;
    float: left;
    margin-right: 4px
  }

  .fundFrame .main_content_wrap_notice .listZone a.accept {
    background-color: var(--btnColor012);
    border: 1px solid var(--bgColor040)
  }

  .fundFrame .main_content_wrap_notice .listZone a.notAccept {
    background-color: var(--btnColor014);
    border: 1px solid var(--bdColor018)
  }

  .fundFrame .main_content_wrap_notice .listZone a.accept:hover {
    background-color: var(--btnColor012_h)
  }

  .fundFrame .main_content_wrap_notice .listZone a.notAccept:hover {
    background-color: var(--btnColor014_h)
  }

  .fundFrame .fundShow .fundBrick .cube .frame {
    display: flex
  }

  .fundFrame .fundShow .fundBrick .cube .frame span {
    color: var(--txtColor_05);
    font-size: 19px;
    margin-left: 9px;
    cursor: pointer
  }

  .fundFrame .fundShow .fundBrick .cube h4 {
    margin-bottom: 16px;
    color: var(--txtColor95);
    font-size: 14px;
    font-weight: 700
  }

  .fundFrame .fundShow .fundBrick .cube h4 {
    margin-bottom: 16px;
    color: var(--txtColor_032);
    font-size: 14px
  }

  .fundFrame .fundShow .fundBrick .cube .money {
    font-size: 32px;
    color: var(--txtColor_032);
    font-family: var(--font-family) !important;
    font-weight: 700 !important
  }

  .fundFrame .searchZone {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 31px;
    margin-bottom: 20px
  }

  .fundFrame .searchZone .inputZone {
    display: flex;
    align-items: center;
    float: left;
    margin-right: 5px
  }

  .fundFrame .searchZone .input_content {
    width: 200px;
    height: 40px;
    padding-left: 11px;
    background-repeat: no-repeat;
    background-position: 10px;
    box-sizing: border-box;
    position: relative;
    color: var(--txtColor_03);
    background-color: var(--main);
    border: 1px solid var(--bdColor_008) !important;
    font-family: var(--font-family) !important;
    font-weight: 400 !important
  }

  .fundFrame .searchZone .input_content input {
    line-height: 40px;
    height: 40px;
    color: var(--txtColor_03);
    font-family: var(--font-family) !important;
    font-weight: 400 !important
  }

  .fundFrame .searchZone .inputZone span {
    margin: 0 7px
  }

  .fundFrame .searchZone .input_content input:focus {
    border-bottom: none !important;
    box-shadow: none !important
  }

  .fundFrame .searchZone .input_content input::placeholder {
    color: var(--txtColor_03);
    font-size: 14px;
    font-weight: 700
  }

  .fundFrame .fundList .moneyBtns .search {
    border: none;
    background-color: var(--bgColor017)
  }

  .main_content_wrap_notice .listZone table tr td span.done {
    color: var(--txtColor_05) !important
  }

  .main_content_wrap_notice .listZone table tr td span.cancel {
    color: var(--txtColor69)
  }

  .main_content_wrap_notice .listZone table tr td span.greyT {
    color: var(--txtColor54)
  }

  .main_content_wrap_notice .listZone table tr td span.greenT {
    color: var(--txtColor82)
  }

  .main_content_wrap_notice .listZone table tr td .rem_checkFrame {
    display: flex
  }

  .main_content_wrap_notice .listZone table tr td .rem_check {
    color: var(--txtColor66);
    font-size: 14px;
    font-weight: 700;
    margin-right: 12px
  }

  .main_content_wrap_notice .listZone table tr td .rem_check span {
    margin-left: -9px
  }

  .balanceFrame .firstFrame {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .balanceFrame .inputFrame {
    display: flex;
    align-items: center;
    margin-bottom: 20px
  }

  .balanceFrame .inputFrame .info .txt {
    font-size: 14px;
    color: var(--txtColor_03);
    margin-right: 6px;
    font-weight: 700
  }

  .balanceFrame .info .txt span {
    color: var(--txtColor012)
  }

  .balanceFrame .input_content {
    width: 226px;
    height: 40px;
    padding-left: 11px;
    background-repeat: no-repeat;
    background-position: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
    color: var(--txtColor112);
    background-color: var(--main);
    border: 1px solid var(--bdColor03) !important
  }

  .balanceFrame .input_content select {
    display: block;
    border: none;
    background: none !important;
    padding: 0;
    height: 40px
  }

  .balanceFrame .input_content select:focus {
    outline: 0px
  }

  .balanceFrame .searchZone {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 0
  }

  .redTxt {
    color: var(--txtColor69) !important
  }

  .titlePic01 img {
    width: 100%;
    height: auto
  }

  .promotionFrame .btnZone {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px
  }

  .promotionFrame .btnZone a {
    width: 240px;
    height: 40px;
    color: var(--txtColor02);
    background-color: var(--btnColor024);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    transition: .2s ease-out
  }

  .promotionFrame .btnZone a:hover {
    background-color: var(--btnColor024_h)
  }

  .promotionFrame .contentZone .txt {
    padding-bottom: 100px
  }

  .recommendedFrame .firstFrame {
    display: flex;
    justify-content: space-between
  }

  .recommendedFrame .moneyBtns .search {
    background-color: var(--btnColor025);
    color: var(--txtColor02);
    border: none
  }

  .recommendedFrame .moneyBtns .search:hover {
    background-color: var(--btnColor025_h)
  }

  .recommendedFrame .firstFrame .tabZone {
    width: 100%
  }

  .recommendedFrame .searchZone {
    width: 50%;
    margin-top: 0 !important;
    justify-content: flex-end
  }

  .recommendedFrame .lobbyBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 25px
  }

  .recommendedFrame .lobbyBtns .btnM {
    height: 40px;
    padding: 0 19px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--txtColor66);
    font-weight: 700;
    cursor: pointer;
    transition: .4s ease-out;
    margin-right: 4px;
    background-color: var(--btnColor035);
    border: 1px solid var(--bdColor038)
  }

  .recommendedFrame .lobbyBtns .btnM:hover {
    background-color: var(--btnColor028);
    border: 1px solid var(--bdColor044);
    color: var(--txtColor02)
  }

  .recommendedFrame .lobbyBtns .chooseBtn {
    background-color: var(--bgColor033)
  }

  .recommendedFrame .lobbyBtns .reset {
    background-color: var(--btnColor029);
    color: var(--txtColor02)
  }

  .recommendedFrame .lobbyBtns .reset:hover {
    background-color: var(--btnColor029_h)
  }

  .recommendedFrame .lobbyBtns .chooseBtn {
    background-color: var(--btnColor028);
    border: 1px solid var(--bdColor044);
    color: var(--txtColor02)
  }

  .recommendedFrame .qrFrame {
    border: 1px solid var(--bdColor038);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px
  }

  .recommendedFrame .qrFrame .qrCode {
    background-color: var(--bgColor017);
    box-shadow: var(--shadow07) 0 0 .6em;
    border: 1px solid var(--bdColor038);
    position: relative
  }

  .recommendedFrame .qrFrame .qrCode img {
    width: 306px
  }

  .recommendedFrame .qrFrame .codeNO {
    font-size: 36px;
    font-weight: 800;
    font-family: arial;
    color: var(--txtColor66);
    margin-top: 30px
  }

  .recommendedFrame .qrFrame .btnP {
    width: 320px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-top: 30px;
    cursor: pointer;
    border: 0px
  }

  .recommendedFrame .qrFrame .txt {
    color: var(--txtColor66);
    font-size: 14px;
    margin-top: 20px
  }

  .recommendedFrame .qrFrame .qrCode .copyMSG {
    background-color: #f4f4f4f2;
    color: var(--txtColor66);
    font-size: 14px;
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow07) 0 0 .5em;
    border-radius: 20px;
    left: 50%;
    bottom: -11px;
    transform: translate(-50%);
    position: absolute;
    opacity: 0
  }

  .aniFrameNotice {
    min-height: 780px
  }

  .main_content_wrap_notice .listZone table tr th.checkTH {
    width: 5%
  }

  .main_content_wrap_notice .listZone table tr td.checkUse01 .checkFrame {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .messageFrame .listZone td.checkUse01 .rem_check {
    margin-right: 0
  }

  .messageFrame .listZone td.checkUse01 .rem_check [type=checkbox] + label {
    height: 20px;
    line-height: 20px;
    padding-left: 15px
  }

  @media (max-width: 1440px) {
    .main_content_wrap_notice, .titlePic {
      width: 90% !important
    }

    .fundFrame .moneyBtns .btnM {
      padding: 0 12px;
      margin-left: 6px
    }
  }

  @media (max-width: 1407px) {
    .fundFrame .searchZone .input_content {
      width: 156px
    }

    .fundFrame .tabZone ul li h4 {
      font-size: 18px
    }
  }

  @media (max-width: 1281px) {
    .fundFrame .tabZone ul li h4 {
      font-size: 16px
    }
  }

  @media (max-width: 1192px) {
    .bHisFrame .firstFrame {
      flex-direction: column
    }

    .bHisFrame .firstFrame .tabZone {
      width: 100%
    }

    .bHisFrame .searchZone {
      width: 100%;
      justify-content: flex-start
    }

    .balanceFrame .firstFrame {
      flex-direction: column
    }

    .balanceFrame .firstFrame .tabZone {
      width: 100%
    }

    .balanceFrame .searchZone {
      width: 100%;
      justify-content: flex-start
    }
  }

  .aniMSG {
    -webkit-animation: aniMSG-ba24923c 5s 1;
    animation: aniMSG-ba24923c 5s 1
  }

  @keyframes leftIn-ba24923c {
    0% {
      -webkit-transform: translateX(50%);
      -moz-transform: translateX(50%);
      -o-transform: translateX(50%);
      transform: translate(50%);
      opacity: 0
    }

    to {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translate(0);
      opacity: 1
    }
  }

  @keyframes aniMSG-ba24923c {
    0% {
      opacity: 0
    }

    50% {
      opacity: 1
    }

    to {
      opacity: 0
    }
  }

  @keyframes downIn-ba24923c {
    0% {
      -webkit-transform: translateY(50%);
      -moz-transform: translateY(50%);
      -o-transform: translateY(50%);
      transform: translateY(50%);
      opacity: 0
    }

    to {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      opacity: 1
    }
  }

  .aniTab {
    -webkit-animation: aniTab-ba24923c 1s infinite;
    animation: aniTab-ba24923c 1s infinite
  }

  @keyframes aniTab-ba24923c {
    0% {
      transform: scale(1)
    }

    50% {
      transform: scale(1.1)
    }

    to {
      transform: scale(1)
    }
  }

  .titlePic .titlePwrapper .leftZone span {
    white-space: nowrap
  }

  .fade-enter-active {
    animation: fade-in-ba24923c 0s
  }

  .fade-leave-active {
    animation: fade-in-ba24923c 0s reverse
  }

  @keyframes fade-in-ba24923c {
    0% {
      opacity: 0
    }

    to {
      opacity: 1
    }
  }

  .main-content {
    min-height: 100vh
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .tAreaFrame {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 10px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .txt {
    font-size: 16px;
    width: 12%;
    color: var(--txtColor_03);
    background-color: var(--bgColor_036);
    border: 1px solid var(--bdColor_015);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .areaFrame {
    background-color: var(--main) !important;
    border: 1px solid var(--bdColor_015);
    width: 95%;
    padding: 10px 0 10px 20px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .areaFrame textarea {
    height: 312px;
    background-color: var(--main);
    width: 98%;
    padding: 20px;
    font-size: 14px;
    color: var(--txtColor36);
    border: 1px solid var(--bdColor_015);
    line-height: 1.6
  }

  .deep_wrap_notice .serviceFrame .serviceArea .sendMSG {
    background-color: var(--bdColor039);
    border: 1px solid var(--bdColor047);
    color: var(--txtColor66);
    font-size: 14px;
    width: 240px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    left: 50%;
    bottom: -11px;
    transform: translate(-50%);
    position: absolute;
    opacity: 0
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea textarea:focus, .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea textarea:focus-visible {
    border: 1px solid var(--bdColor_015);
    outline: none
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame {
    display: flex;
    width: 100%;
    text-align: center;
    height: 42px;
    margin-bottom: 17px;
    align-items: center;
    position: relative
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .txtT {
    color: var(--txtColor66);
    font-size: 14px;
    font-weight: 700;
    width: 5%;
    text-align: left
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content {
    position: relative;
    height: 42px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content input {
    width: 100%;
    height: 42px;
    padding-left: 14px;
    background-repeat: no-repeat;
    margin-bottom: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--txtColor54);
    background-color: var(--bgColor017);
    border: 1px solid var(--bdColor045) !important;
    font-size: 16px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .input_content input::placeholder {
    color: var(--txtColor010);
    font-size: 14px
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .btnP {
    padding: 14px 35px;
    border-radius: 20px;
    position: absolute;
    right: 0
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .serviceArea .inputFrame .input_txt {
    color: var(--txtColor66);
    font-size: 20px;
    font-weight: 700
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .btnZone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .btnZone .btnP {
    height: 40px;
    padding: 0 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    border: 0px;
    transition: .2s ease-out;
    background-color: var(--bgColor_02)
  }

  .deep_wrap_notice .serviceFrame .serviceOuter .btnZone .btnP:hover {
    background-color: var(--btnColor_001) !important;
    color: var(--txtColor01_h) !important
  }

  .deep_wrap_notice .serviceFrame .main_content_wrap_notice .listZone table tr td a.goService {
    font-size: 12px;
    color: var(--txtColor02);
    display: block;
    width: 60px;
    height: 25px;
    line-height: 25px;
    border-radius: 3px;
    margin: 0 auto;
    font-weight: 700;
    transition: .4s ease-out
  }

  .deep_wrap_notice .serviceFrame a.done {
    background-color: var(--btnColor016)
  }

  .deep_wrap_notice .serviceFrame a.done:hover {
    background-color: var(--btnColor016_h)
  }

  .deep_wrap_notice .serviceFrame a.undone {
    background-color: var(--btnColor014)
  }

  .deep_wrap_notice .serviceFrame a.undone:hover {
    background-color: var(--btnColor014_h)
  }

  .deep_wrap_notice .serviceFrame a.write {
    background-color: var(--btnColor017)
  }

  .deep_wrap_notice .serviceFrame a.write:hover {
    background-color: var(--btnColor017_h)
  }

  .deep_wrap_notice .serviceFrame .letterZone, .deep_wrap_notice .serviceFrame .letterBack {
    padding: 26px 32px;
    border: 1px solid var(--bdColor070);
    background-color: var(--bgColor_024)
  }

  .deep_wrap_notice .serviceFrame .letterZone .time {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: var(--txtColor95);
    font-size: 14px;
    font-weight: 100;
    position: relative;
    padding-bottom: 7px
  }

  .deep_wrap_notice .serviceFrame .letterZone .time .name {
    margin-right: 26px;
    position: relative
  }

  .deep_wrap_notice .serviceFrame .letterZone .time .name:after {
    content: "";
    width: 1px;
    height: 13px;
    background-color: var(--txtColor116);
    position: absolute;
    right: -12px
  }

  .deep_wrap_notice .serviceFrame .letterZone .time .btnP1 {
    padding: 9px 14px;
    border-radius: 20px;
    position: absolute;
    left: 0
  }

  .deep_wrap_notice .serviceFrame .letterZone p {
    font-size: 14px;
    color: var(--txtColor95);
    margin-top: 12px;
    line-height: 2;
    text-align: justify
  }

  .deep_wrap_notice .serviceFrame .btnFrame {
    margin-top: 30px
  }

  .deep_wrap_notice .serviceFrame .btnFrame .btnP1 {
    height: 40px;
    padding: 0 47px;
    color: var(--txtColor02);
    background-color: var(--bgColor_02);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    float: right;
    margin-top: 23px;
    cursor: pointer;
    transition: .2s ease-out
  }

  .deep_wrap_notice .serviceFrame .btnFrame .btnP1:hover {
    background-color: var(--btnColor_001) !important;
    color: var(--txtColor01_h) !important
  }

  .deep_wrap_notice .serviceFrame .depositP {
    line-height: 3.5 !important
  }

  .deep_wrap_notice .serviceOuterReply {
    display: none;
    transition: .4s ease-out
  }

  .deep_wrap_notice .btnReply01 {
    display: none !important
  }

  .deep_wrap_notice .btnReply01 .btnP1, .deep_wrap_notice .btnReply02 .btnP1 {
    font-size: 14px;
    display: flex;
    border-radius: 20px;
    font-weight: 700;
    transition: .4s ease-out;
    cursor: pointer;
    align-items: center;
    width: 240px !important
  }

  .deep_wrap_notice .serviceReplyFrame .serviceOuterReply {
    display: block !important
  }

  .deep_wrap_notice .serviceReplyFrame .timeReply, .deep_wrap_notice .serviceReplyFrame .btnReply {
    display: none !important
  }

  .deep_wrap_notice .serviceReplyFrame .btnReply01 {
    display: flex !important;
    justify-content: center;
    align-items: center
  }

  .deep_wrap_notice .contentZone .time .title, .deep_wrap_notice .contentZone .time span.timeR {
    color: var(--txtColor95) !important
  }

  button:focus {
    outline: none;
    background: none
  }


}

@media (max-width: 500px) {

  .customer-table table td.w55 {
    width: 60vw;
    max-width: 60vw;
    padding: 2vw
  }

  .customer-table table td.w25 {
    width: 20vw;
    max-width: 20vw
  }

  .full-table table.customer {
    width: 100vw
  }

  .full-table table.customer tbody tr th, .full-table table.customer tbody tr td {
    text-align: left;
    padding: 1.4vw;
    font-size: .81rem;
    border-bottom: 1px solid var(--borderColor09);
    color: var(--txtColor19);
    vertical-align: middle
  }

  .full-table table.customer tbody tr th {
    font-weight: 600;
    background: var(--bgColor33);
    padding: 2.4vw
  }

  .full-table table.customer tbody tr td input {
    margin-bottom: 2vw
  }

  .full-table table.customer tbody tr td input, .full-table table.customer tbody tr td textarea {
    border: 1px solid var(--borderColor05);
    padding: 2vw;
    width: 72vw;
    line-height: 1.6;
    font-size: .86rem;
    font-family: Microsoft JhengHei, sans-serif
  }

  .full-table table.customer tbody tr td p {
    margin-bottom: 1vw
  }

  .customer-table button {
    width: 20vw;
    padding: 2.2vw;
    border-radius: .5vw;
    -webkit-border-radius: .5vw;
    -moz-border-radius: .5vw;
    -ms-border-radius: .5vw;
    -o-border-radius: .5vw;
    font-size: .81rem;
    font-weight: 600
  }

  button.write-button {
    margin: 1vw;
    background: var(--bgColor24);
    color: var(--txtColor01)
  }

  button.consultation-button {
    margin: 1vw;
    width: auto;
    min-width: 22vw
  }

  button.checknow-button {
    background: var(--bgColor24);
    color: var(--txtColor01)
  }

  button.finish-button {
    color: var(--txtColor01);
    background: var(--btnColor10)
  }

  button.finish-button.not-read {
    color: var(--txtColor39)
  }

  .reply-box {
    padding: 3.4vw;
    background: var(--bgColor06);
    border-top: 2px solid var(--borderColor19);
    font-size: .93rem
  }

  .reply-box .subject {
    width: 93.2vw;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.4vw 3.4vw;
    background: var(--bgColor19);
    border: 1px solid var(--borderColor20);
    color: var(--txtColor15)
  }

  .reply-box .subject div {
    font-weight: 600;
    color: var(--txtColor19)
  }

  .reply-box textarea {
    width: 93.2vw;
    margin: 2.6vw auto 0;
    padding: 3.4vw;
    background: var(--bgColor01);
    border: 1px solid var(--borderColor20);
    line-height: 1.6
  }

  .reply-box .btn-center {
    padding: 1.4vw 0 0
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .reply-box, .full-table table.customer tbody tr th, .full-table table.customer tbody tr td, .full-table table.customer tbody tr td input, .full-table table.customer tbody tr td textarea {
      font-size: 1.6rem
    }

    .customer-table button {
      font-size: 1.4rem
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .reply-box, .full-table table.customer tbody tr th, .full-table table.customer tbody tr td, .full-table table.customer tbody tr td input, .full-table table.customer tbody tr td textarea {
      font-size: 2.4rem
    }

    .customer-table button {
      font-size: 1.8rem
    }
  }
}


</style>

