<template>
  <div class="modals-container">
    <div class="vfm vfm--inset vfm--fixed" modalicontype="none"
         style="z-index: 1007;">
        <div class="vfm__container vfm--absolute vfm--inset vfm--outline-none NAV_alert01 show"
             aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
            <div class="vfm__content pupop_content fadeIn animated">
                <span></span>
                <div class="top modal__title">
                    <span class="icon-iconExclamation01"></span>
                    <h4>안내</h4>
                </div>
                <div  class="popWrapper">
                    <div class="txt">{{ msg }}</div><!---->
                    <div class="modal__action">
                        <div class="btnFrame1">
                            <div class="btnP godeleteMail01 blueLBtn" @click="clickBtn">확인</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "Alram",
  props: ['toggleAlarm', 'msg', 'href'],
  methods: {
    clickBtn() {
        if(this.href) this.$router.push(this.href)
        this.toggleAlarm();
    }
  }
}
</script>


<style scoped>
.vfm--inset {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.vfm--absolute {
  position: absolute;
}

.vfm--fixed {
  position: fixed;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9.5px 16px
}

.NAV_alert01 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: var(--bgColor039);
    z-index: 999
}

.NAV_alert01 .pupop_content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: var(--main);
    border-radius: 15px;
    box-shadow: var(--shadow08) .1em .1em .3em;
    -o-background-size: contain;
    background-size: contain
}

.NAV_alert01 .pupop_content .top {
    background-color: var(--bgColor_07)!important;
    height: 51px;
    border-radius: 15px 15px 0 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.NAV_alert01 .pupop_content .top .XX {
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
    -webkit-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out
}

.NAV_alert01 .pupop_content .top .XX:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
}

.NAV_alert01 .pupop_content .top .XX span {
    font-size: .9vw;
    color: var(--txtColor_01);
    font-weight: 700;
    margin-right: 0
}

.NAV_alert01 .pupop_content .top span {
    color: var(--txtColor105);
    font-size: 22px;
    margin-right: 10px
}

.NAV_alert01 .pupop_content .top h5 span {
    margin-right: 0
}

.NAV_alert01 .pupop_content .top h4 {
    color: var(--txtColor105);
    font-size: 18px
}

.NAV_alert01 .pupop_content .popWrapper {
    margin: 0 auto;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 31px 63px;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.NAV_alert01 .pupop_content .popWrapper .XX {
    position: absolute;
    right: 20px;
    top: 17px;
    cursor: pointer;
    -webkit-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
    display: none
}

.NAV_alert01 .pupop_content .popWrapper .XX:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
}

.NAV_alert01 .pupop_content .popWrapper .XX span {
    font-size: 22px;
    color: var(--txtColor113);
    font-weight: 700
}

.NAV_alert01 .pupop_content .popWrapper .txt {
    color: var(--txtColor_03);
    font-size: 16px;
    line-height: 1.6;
    text-align: center
}

.NAV_alert01 .pupop_content .popWrapper .txt span {
    color: var(--txtColor_049)
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame2 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame2 .btnP {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 13px 72px;
    border-radius: 50px;
    cursor: pointer;
    margin-left: 10px
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame2_sport .btnP {
    width: 189px;
    height: 46px;
    white-space: nowrap
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame2_sport .btnP span {
    font-size: 25px;
    margin-right: 7px
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame2_sport .btnP img {
    height: 27px;
    margin-right: 7px
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame1 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px
}

.NAV_alert01 .pupop_content .popWrapper .btnFrame1 .btnP {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    padding: 13px 72px
}

.NAV_alert01 .inputFrame {
    width: 110%;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid var(--bdColor045)!important;
    padding-top: 27px
}

.NAV_alert01 .inputFrame .info {
    width: 19%
}

.NAV_alert01 .inputFrame .info .txt {
    color: var(--txtColor66);
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    margin-top: 0;
    line-height: 0px;
    display: inline-block;
    white-space: nowrap
}

.NAV_alert01 .inputFrame .info .txt span {
    color: var(--btnColor024)
}

.NAV_alert01 .inputFrame .input_content {
    width: 58%;
    height: 42px;
    background-repeat: no-repeat;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--txtColor77);
    background-color: var(--bgColor017);
    border: 1px solid var(--bdColor045)!important;
    font-size: 16px;
    position: relative
}

.NAV_alert01 .inputFrame .input_content input {
    padding-left: 14px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 93%
}

.NAV_alert01 .inputFrame .input_content input:focus {
    border-bottom: none!important;
    box-shadow: none!important
}

.NAV_alert01 .inputFrame .input_content input::-webkit-input-placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

.NAV_alert01 .inputFrame .input_content input:-moz-placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

.NAV_alert01 .inputFrame .input_content input::-moz-placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

.NAV_alert01 .inputFrame .input_content input:-ms-input-placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

.NAV_alert01 .inputFrame .input_content input::placeholder {
    color: var(--txtColor010);
    font-size: 14px
}

.NAV_alert01 .inputFrame .input_content .hintUse {
    position: absolute;
    bottom: -57px
}

.NAV_alert01 .inputFrame .input_content .hintUse .hint01 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--txtColor91)
}

.NAV_alert01 .inputFrame .input_content .hintUse .hint02 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--txtColor86);
    margin-top: 7px
}

.NAV_alert01 .inputFrame .input_content .hintUse span {
    font-size: 20px;
    margin-right: 8px
}

.NAV_alert01 .inputFrame .input_content .hintUse h5 {
    font-size: 14px
}

.NAV_alert01 .pupop_content .popWrapper button {
    height: 42px;
    width: 21%;
    line-height: 42px;
    text-align: center;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 700;
    border: none;
    background: var(--btnColor025);
    color: var(--txtColor02);
    cursor: pointer;
    -webkit-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    -moz-transition: .4s ease-out;
    transition: .4s ease-out
}

.NAV_alert01 .pupop_content .popWrapper button:hover {
    background: var(--btnColor025_h)
}

.NAV_alert01_jpay .pupop_content .popWrapper {
    margin: 0 auto;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 25px 63px 33px;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.NAV_alert01_jpay .pupop_content .popWrapper .XX {
    position: absolute;
    right: 20px;
    top: 16px;
    cursor: pointer;
    -webkit-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
    display: none
}

.NAV_alert01_jpay .pupop_content .popWrapper .btnFrame2 {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 6px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb01 {
    height: 240px;
    width: 112%;
    overflow-y: auto;
    display: block;
    padding-top: 6px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb01::-webkit-scrollbar-track {
    background-color: #f7f7f7
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb01::-webkit-scrollbar {
    width: 3px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb01::-webkit-scrollbar-thumb {
    background-color: #cfd4e3
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table tr {
    position: relative
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder tr:before {
    content: "";
    height: 1px;
    width: 120%;
    background-color: #d5d5d5;
    position: absolute;
    top: -15%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder tr:nth-child(2n):before {
    content: "";
    height: 1px;
    width: 120%;
    background-color: #fff;
    position: absolute;
    top: -15%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table tr td {
    color: #7e7e7e;
    font-size: 24px;
    padding: 6px 39px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table tr td.pb100 {
    padding-bottom: 20px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table tr td span.bt {
    color: #000
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb02 {
    margin-top: 33px
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb02 tr:before {
    content: "";
    height: 1px;
    width: 109%;
    background-color: #d5d5d5;
    position: absolute;
    top: -18%;
    left: 55%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb02 tr:nth-child(2n):before {
    content: "";
    height: 1px;
    width: 120%;
    background-color: #fff;
    position: absolute;
    top: -15%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb02 tr:after {
    content: "";
    height: 1px;
    width: 109%;
    background-color: #d5d5d5;
    position: absolute;
    top: 124%;
    left: 55%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.NAV_alert01_jpay .pupop_content .popWrapper .listOrder table.tb02 tr td span {
    font-size: 28px
}

.NAV_alert_wallet .pupop_content .XX {
    display: none
}

.NAV_alert_wallet .pupop_content .top span {
    margin-right: 10px
}

.NAV_alert_wallet .pupop_content .popWrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 31px 63px;
    flex-wrap: wrap
}

</style>
